import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AzNotifierService } from '@azigrene/components';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { Usuario } from '@shared/models/auth.model';
import { LoginAction, LoginErrorAction, LoginSuccessAction } from '@store/auth/auth.actions';
import { PasswordService } from '../../service/password.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {

  usuario: Usuario;

  loginForm: FormGroup;
  recuperacion = false;
  loading = false;

  constructor(
    private formBuilder: FormBuilder,
    private translateService: TranslocoService,
    private router: Router,
    private store: Store,
    private actions$: Actions,
    private messageService: AzNotifierService,
    private passwordService: PasswordService
  ) {
    this.loginForm = formBuilder.group({
      email: ['', {validators: [Validators.required, Validators.email], updateOn: 'blur'}],
      password: ['', {validators: [Validators.required], updateOn: 'blur'}],
    });
  }

  ngOnInit(): void {
    this.actions$.pipe(ofActionSuccessful(LoginSuccessAction)).subscribe(() => this.router.navigateByUrl('/home'));
    this.actions$.pipe(ofActionSuccessful(LoginErrorAction)).subscribe(() =>
      this.messageService.add({
        detail: this.translateService.translate('login.falloPass'),
        summary: this.translateService.translate('passwordReset.mensaje.error'),
        key: 'fracaso',
        severity: 'error',
      })
    );
  }

  enviarLogin(): void {
    this.store.dispatch(
      new LoginAction({
        email: this.loginForm.value.email,
        password: this.loginForm.value.password,
      })
    );
  }

  olvidastePassword(): void {
    if (!this.recuperacion) {
      this.recuperacion = true;
    }
  }

  volver(): void {
    if (this.recuperacion) {
      this.recuperacion = false;
    }
  }

  recuperarPassword(): void {
    this.loading = true;
    this.passwordService
      .resetPassword(this.loginForm.value.email)
      .toPromise()
      .then(() => {
        this.loading = false;
        this.messageService.add({
          detail: this.translateService.translate('passwordReset.mensaje.correo'),
          summary: this.translateService.translate('passwordReset.mensaje.reiniciada'),
          key: 'exito',
          severity: 'success',
        });
      })
      .catch((error) => {
        this.loading = false;
        this.messageService.add({
          detail: error.error.message,
          summary: this.translateService.translate('passwordReset.mensaje.error'),
          key: 'fracaso',
          severity: 'error',
        });
      });
  }

}
