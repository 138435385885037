import { Action, Selector, State, StateContext } from '@ngxs/store';
import * as authActions from './auth.actions';
import { AuthService } from 'src/app/service/auth.service';
import { LoginResponse } from 'src/app/shared/models/auth.model';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

export interface AuthStateModel {
  user: LoginResponse;
  token: string;
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    user: null,
    token: null
  }
})
@Injectable()
export class AuthState {
  @Selector()
  static getToken(state: AuthStateModel) {
    return state.token;
  }

  @Selector()
  static getUser(state: AuthStateModel) {
    return state.user;
  }

  constructor(private authService: AuthService, private router: Router) {
  }

  @Action(authActions.LoginAction)
  login(ctx: StateContext<AuthStateModel>, action: authActions.LoginAction) {
    return this.authService.login(action.payload).subscribe(
      response => {
        ctx.dispatch(new authActions.LoginSuccessAction(response));
      },
      error => {
        ctx.dispatch(new authActions.LoginErrorAction(error));
      }
    );
  }

  @Action(authActions.LoginSuccessAction)
  loginSuccess(ctx: StateContext<AuthStateModel>, action: authActions.LoginSuccessAction) {
    return ctx.setState({ user: action.payload, token: action.payload.token });
  }

  @Action(authActions.LogoutAction)
  logout(ctx: StateContext<AuthStateModel>) {
    ctx.setState({ user: null, token: null });
    return this.router.navigate(['/login']);
    // return ctx.dispatch(new authActions.LogoutSuccessAction());
    /*this.authService.logout().subscribe(
      response => {
        ctx.dispatch(new authActions.LogoutSuccessAction());
      },
      error => {
        ctx.dispatch(new authActions.LogoutErrorAction(error));
      }
    );*/
  }

  @Action(authActions.LogoutSuccessAction)
  logoutSuccess(ctx: StateContext<AuthStateModel>) {
  }
}
