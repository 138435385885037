import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PrioridadTarea } from '@shared/models/home.model';
import { TareaActions } from '../../../../modules/frontoffice/actions/TareaActions';
import { TareaService } from '../../../../modules/frontoffice/services/tarea.service';
import { AzOverlayComponent } from '@azigrene/components';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-priority-dropdown',
  template: `
    <div class="p-2 md:w-64">
      <div class="flex items-center justify-center w-full border-b relative">
        <span class=" text-gray-500 h-8 leading-8">{{'priority-dropdown.titulo' | transloco}}</span>
        <svg (click)="dropdown.close();" class="w-4 h-4 absolute right-0 top-0 mt-1 cursor-pointer" fill="none"
             stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
        </svg>
      </div>
      <span class="az-label-uppercase mt-2">{{'priority-dropdown.prioridades' | transloco}}</span>
      <ul class=" space-y">
        <li *ngFor="let p of prioridades" class="-mx-1 p-1 hover:cursor-pointer hover:bg-gray-100 relative"
            (click)="change(p);dropdown.close();">
          <app-tag-prioridad-tarea wrapperClass="w-full " tagClass="cursor-pointer h-8 leading-8 w-full text-center"
                                   [priorityId]="p.id">
          </app-tag-prioridad-tarea>
          <svg *ngIf="p.id === _prioridadActivaId"
               class="text-black w-4 h-4 absolute right-0 top-0 mt-3 mr-3" fill="none" stroke="currentColor"
               viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 13l4 4L19 7"></path>
          </svg>
        </li>
      </ul>
      <button azButton styleClass="w-full justify-center mt-2"
              (click)="change(null);$event.stopPropagation()">
        {{'priority-dropdown.quitar-prioridad' | transloco}}
      </button>
    </div>
  `,
  styles: []
})
export class PriorityDropdownComponent implements OnInit {
  @Input() dropdown: AzOverlayComponent;
  @Input() contratoId: number;
  @Input() tareaId: number;
  @Input() prioridad: PrioridadTarea;
  @Input() prioridadId: number;

  @Output() onChange = new EventEmitter<number>();
  @Output() onUpdateSuccess = new EventEmitter();

  prioridades = [
    { id: 1, label: 'Baja', class: 'bg-green-200 text-black' },
    { id: 2, label: 'Media', class: 'bg-yellow-200 text-black' },
    { id: 3, label: 'Alta', class: 'bg-red-200 text-black' },
    { id: 4, label: 'Urgente', class: 'bg-red-600 text-white' }
  ];
  _prioridadActivaId: number;

  constructor(private tareaService: TareaService) {
  }

  ngOnInit() {
    this._prioridadActivaId = this.prioridad ? this.prioridad.id : this.prioridadId;
  }

  change(p: { id: number; label: string; class: string }) {
    if (p) {
      this.onChange.emit(p.id);
      this._prioridadActivaId = p.id;
      this.updatePriority();
    } else {
      this.onChange.emit(null);
      this._prioridadActivaId = null;
      this.updatePriority();
    }
  }

  updatePriority() {
    this.tareaService.accionLista([this.tareaId], TareaActions.SET_PRIORITY, { prioridadId: this._prioridadActivaId }, this.contratoId).pipe(take(1)).subscribe(n => {
      if (n) this.onUpdateSuccess.emit();
      this.dropdown.close();
    });
  }
}
