import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HistoricoRevisionTarea, HistoricoRevisionTareaDatos } from '@shared/models/home.model';
import { environment } from 'src/environments/environment';
import { PaginatedResponse, SearchRequest } from '@azigrene/searchrequest';

@Injectable()
export class HistoricoRevisionService {
  constructor(private http: HttpClient) {
  }

  getAll(contratoId, tareaId, revisionId, searchRequest = new SearchRequest()) {
    return this.http.post<PaginatedResponse<HistoricoRevisionTarea>>(
      `${
        environment.urls.api
      }/contracts/${contratoId}/tasks/${tareaId}/revisions/${revisionId}/history`,
      searchRequest
    );
  }


  update(id: number, contratoId, tareaId, revisionId, data: HistoricoRevisionTareaDatos) {
    return this.http.post<HistoricoRevisionTarea>(`${environment.urls.api}/contracts/${contratoId}/tasks/${tareaId}/revisions/${revisionId}/history/${id}/update`, data);
  }

  delete(id: number, contratoId, tareaId, revisionId) {
    return this.http.delete<HistoricoRevisionTarea>(`${environment.urls.api}/contracts/${contratoId}/tasks/${tareaId}/revisions/${revisionId}/history/${id}/delete`);
  }
}
