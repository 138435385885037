<div class="h-full w-full bg-primary-darker">
    <div class="container mx-auto flex justify-center">
        <div class="m-16 text-center w-full max-w-sm">
            <div class="py-4">
                <span class="tracking-wide text-white text-5xl font-semibold uppercase">{{
          'login.titulo' | transloco
        }}</span>
            </div>
            <form class="bg-gray-50 rounded-lg shadow-md p-8 w-full" [formGroup]="loginForm" (ngSubmit)="enviar()">
                <div class="az-form-group mt-4">
                    <label class="text-left">{{ 'login.contrasena' | transloco }}</label>
                    <input azInputText type="password" placeholder="{{ 'login.contrasena' | transloco }}" formControlName="password" />
                </div>
                <div class="az-form-group mt-4">
                    <label class="text-left">{{ 'login.confirmar' | transloco }}</label>
                    <input azInputText type="password" placeholder="{{ 'login.confirmar' | transloco }}" formControlName="confirm" />
                </div>
                <nz-spin *ngIf="loading" class="mt-4" nzSimple></nz-spin>
                <div *ngIf="!loading" class="mt-8">
                    <button azButton level="primary" [disabled]="!loginForm.valid" type="submit">{{ 'passwordReset.boton.cambiar' | transloco }}</button>
                </div>
            </form>
            <div *ngIf="!loading" class="mt-4">
                <button (click)="volver()" class="text-center text-accent-200">{{ 'passwordReset.boton.volver' | transloco }}</button>
            </div>
        </div>
    </div>
</div>
