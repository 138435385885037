import { Component } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { en_US, es_ES, NzI18nService } from 'ng-zorro-antd/i18n';
import moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'fiscalizacion-web';

  constructor(private translateService: TranslocoService, private i18n: NzI18nService) {
    this.translateService.setDefaultLang('es');
    this.translateService.setActiveLang('es');

    this.i18n.setLocale(es_ES);

    moment.locale('es');
    translateService.langChanges$.subscribe((event: string) => {
      switch (event) {
        case 'es':
          this.i18n.setLocale(es_ES);
          break;
        case 'en':
          this.i18n.setLocale(en_US);
          break;
        default:
          this.i18n.setLocale(es_ES);
      }
    });
  }
}
