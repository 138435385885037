import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Contrato } from '@shared/models/home.model';
import { PaginatedResponse, SearchRequest } from '@azigrene/searchrequest';

@Injectable()
export class BackofficeContratoService {
  constructor(private http: HttpClient) {
  }

  all(searchRequest: SearchRequest = new SearchRequest()) {
    return this.http.post<PaginatedResponse<Contrato>>(`${environment.urls.api}/backoffice/contracts`, searchRequest);
  }
  list() {
    return this.http.get<Contrato[]>(`${environment.urls.api}/backoffice/contracts`);
  }

  one(id: number) {
    return this.http.get<Contrato>(`${environment.urls.api}/backoffice/contracts/${id}`);
  }

  create(data: Contrato) {
    return this.http.post<Contrato>(`${environment.urls.api}/backoffice/contracts/create`, data);
  }

  update(id: number, data: Contrato) {
    return this.http.post<Contrato>(
      `${environment.urls.api}/backoffice/contracts/${id}/update`,
      data
    );
  }

  delete(id: number) {
    return this.http.delete<Contrato>(`${environment.urls.api}/backoffice/contracts/${id}/delete`);
  }

  createWithTemplate(data: any) {
    return this.http.post<Contrato>(`${environment.urls.api}/backoffice/contracts/createWithTemplate`, data);
  }
}
