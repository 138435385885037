import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {PrioridadTarea} from '@shared/models/home.model';

@Component({
  selector: 'app-estado-selector',
  template: `
    <az-overlay #dd class="inline-flex w-full {{ wrapperClass }}" position="BOTTOM_RIGHT" [template]="tpl" (click)="$event.stopPropagation()">
      <button class="group flex items-center justify-between hover:cursor-pointer w-full ">
        <ng-container *ngIf="!customButton">
          <app-tag-estado-revision *ngIf="_estadoActivoId" wrapperClass="space-x-2" [statusId]="_estadoActivoId"></app-tag-estado-revision>
          <az-icon icon="chevron-down"></az-icon>
        </ng-container>
        <ng-container *ngIf="customButton">
          <ng-content select="[content]"></ng-content>
        </ng-container>
      </button>
      <ng-template #tpl>
        <az-dropdown-content [dropdown]="dd" titulo="Cambiar estado">
          <ul class="divide-y">
            <li *ngFor="let e of _estadosSeleccionables" class="px-3 py-1 hover:color-bg-secondary hover:cursor-pointer" (click)="$event.stopPropagation(); change(e); dd.close()">
              <app-tag-estado-revision wrapperClass="space-x-2" [statusId]="e.id"></app-tag-estado-revision>
            </li>
          </ul>
        </az-dropdown-content>
      </ng-template>
    </az-overlay>
  `,
  styles: [],
})
export class EstadoSelectorComponent implements OnInit, OnChanges {
  @Input() status: PrioridadTarea;
  @Input() statusId: number;
  @Input() customButton = false;
  @Input() wrapperClass = 'w-full h-full';
  @Input() selectedClass = 'px-2 py-1 inline-flex items-center  w-full';

  @Output() onChange = new EventEmitter();

  estados = [
    {id: 1, label: 'PENDIENTE'},
    {id: 2, label: 'EN PROCESO'},
    {id: 3, label: 'FINALIZADA'},
    {id: 4, label: 'VALIDADA'},
    {id: 5, label: 'RECHAZADA'},
    {id: 6, label: 'CANCELADA'},
  ];

  _estadoActivoId: number;
  _estadosSeleccionables: {id: number; label: string}[];

  constructor() {}

  ngOnInit() {
    this._estadoActivoId = this.status ? this.status.id : this.statusId;
    this.updateEstadosSeleccionables();
  }

  updateEstadosSeleccionables() {
    this._estadosSeleccionables = this.estados.filter((e) => e.id !== this._estadoActivoId);
  }

  change(p: {id: number; label: string; class: string}) {
    if (p) {
      this.onChange.emit(p.id);
      this._estadoActivoId = p.id;
    } else {
      this.onChange.emit(null);
      this._estadoActivoId = null;
    }
    this.updateEstadosSeleccionables();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.statusId) {
      this._estadoActivoId = changes.statusId.currentValue;
      this.updateEstadosSeleccionables();
    }
    if (changes.status) {
      this._estadoActivoId = changes.status.currentValue.id;
      this.updateEstadosSeleccionables();
    }
  }
}
