import {Injectable} from '@angular/core';
import {DocumentoTarea} from '@shared/models/home.model';
import {UploadDocumento} from '@shared/models/generic.model';
import {environment} from '@env/environment.prod';
import {SearchRequest} from '@azigrene/searchrequest';
import {Observable} from 'rxjs';
import {AbstractBackendService} from '@shared/interfaces/service-classes';

@Injectable()
export class DocumentoTareaService extends AbstractBackendService<DocumentoTarea, UploadDocumento, any, any> {

  buildUrlBase(args: {contratoId?: number, tareaId?: number}): string {
    return environment.api.documentoTarea.usuario
      .replace('${contratoId}', args.contratoId.toString())
      .replace('${tareaId}', args.tareaId.toString());
  }

  getAllDocuments(tareaId: number, searchRequest: SearchRequest = new SearchRequest(), contratoId?: number): Observable<DocumentoTarea[]> {
    return this.getHttp().post<DocumentoTarea[]>(this.buildUrlBase({contratoId, tareaId}) + `/list`, searchRequest);
  }

  uploadDocument(tareaId: number, datos: UploadDocumento, contratoId?: number): Observable<DocumentoTarea> {
    const input = new FormData();

    input.append('file', datos.file);
    input.append('nombre', datos.nombre);
    input.append('observaciones', datos.observaciones);

    return this.getHttp().post<DocumentoTarea>(this.buildUrlBase({contratoId, tareaId}) + `/create`, input);
  }
}
