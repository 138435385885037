import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Usuario } from '@shared/models/auth.model';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, ofActionSuccessful, Actions } from '@ngxs/store';
import { LoginAction, LoginSuccessAction } from '@store/auth/auth.actions';
import { AzNotifierService } from '@azigrene/components';
import { PasswordService } from '../../service/password.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnInit {
  usuario: Usuario;

  loginForm: FormGroup;
  usuarioId: number;
  token: string;

  loading: boolean;

  constructor(private formBuilder: FormBuilder, private translateService: TranslocoService, private router: Router, private activatedRoute: ActivatedRoute, private store: Store, private actions$: Actions, private messageService: AzNotifierService, private passwordService: PasswordService) {
    this.loginForm = formBuilder.group({
      password: ['', Validators.required],
      confirm: ['', Validators.required]
    });
  }
  ngOnInit() {
    //this.actions$.pipe(ofActionSuccessful(LoginSuccessAction)).subscribe(() => this.router.navigateByUrl('/home'));
  }

  enviar() {
    let routerTemp = this.router;
    if(this.loginForm.value.password === this.loginForm.value.confirm) {
      this.activatedRoute.paramMap.subscribe(params => {
        this.usuarioId = params['params'].id;
        this.token = params['params'].token;
      });

      this.loading = true;

      this.passwordService.changePassword(this.usuarioId, this.token, this.loginForm.value.password).toPromise().then(result => {
        this.loading = false;
        this.messageService.add({
          detail: this.translateService.translate('passwordReset.mensaje.iniciaSesion'),
          summary: this.translateService.translate('passwordReset.mensaje.reiniciada'),
          key: 'exito',
          severity: 'success'
        });

        setTimeout(function() {
          routerTemp.navigateByUrl('/login');
        }, 2000);
      }).catch(error => {
        this.loading = false;
        this.messageService.add({
          detail: error.error.message,
          summary: this.translateService.translate('passwordReset.mensaje.error'),
          key: 'fracaso',
          severity: 'error'
        });
      });
    } else {
      this.messageService.add({
        detail: this.translateService.translate('passwordReset.mensaje.coincide'),
        summary: this.translateService.translate('passwordReset.mensaje.error'),
        key: 'fracaso',
        severity: 'error'
      });
    }
  }

  volver() {
    this.router.navigateByUrl('/login');
  }
}
