import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DocumentoService} from '../../../modules/frontoffice/services/documento.service';
import {AzModalConfig, AzModalRef, AzNotifierService} from '@azigrene/components';

@Component({
  selector: 'app-subir-documento',
  templateUrl: './subir-documento.dialog.html',
})
export class SubirDocumentoComponent implements OnInit {

  form: FormGroup;
  contratoId: number;
  tareaId: number;
  revisionId: number;

  constructor(private documentoService: DocumentoService, private messageService: AzNotifierService, public ref: AzModalRef, public config: AzModalConfig, private fb: FormBuilder) {
    this.form = this.fb.group({
      file: [null, Validators.required],
      nombre: [null, Validators.required],
      observaciones: [null],
    });
  }

  submit() {
    if (this.contratoId) {
      this.uploadDocumentoContrato();
    } else if (this.tareaId) {
      //  TODO - tarea
    } else if (this.revisionId) {
      //  TODO - revision
    }
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      this.form.get('file').setValue(file);
    }
  }

  uploadDocumentoContrato() {
    this.documentoService.uploadDocumentoContrato(this.contratoId, this.form.value).subscribe(
      (value) => {
        this.messageService.add({
          detail: '',
          summary: 'Estado actualizado',
          key: 'messages',
          severity: 'info',
        });
        this.ref.close(true);
      },
      (error) => {
        this.messageService.add({
          detail: error.error.message,
          summary: 'Error al actualizar estados',
          key: 'messages',
          severity: 'error',
        });
      }
    );
  }

  ngOnInit(): void {
    if (this.config.data) {
      this.contratoId = this.config.data.contratoId;
      this.tareaId = this.config.data.tareaId;
      this.revisionId = this.config.data.revisionId;
    }
  }

}
