import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-estado-revision',
  templateUrl: './estado-revision.component.html'
})
export class EstadoRevisionComponent implements OnInit {
  // TODO: Llamar a base de datos para obtener los estados -> Traducir
  estados = [
    { id: 1, label: 'PENDIENTE' },
    { id: 2, label: 'EN PROCESO' },
    { id: 3, label: 'FINALIZADA' },
    { id: 4, label: 'VALIDADA' },
    { id: 5, label: 'RECHAZADA' },
    { id: 6, label: 'CANCELADA' }
  ];
  @Input() onlyActive = false;
  @Input() status;
  estadoActivo;
  constructor() {}

  ngOnInit() {
    this.estadoActivo = this.estados.find(e => e.id == this.status);
  }
}
