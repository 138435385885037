import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { PaginatedResponse, SearchRequest } from '@azigrene/searchrequest';
import { ContratoRol, Miembro } from '@shared/models/auth.model';

@Injectable()
export class BackofficeMiembroService {
  constructor(private http: HttpClient) {
  }

  all() {
    return this.http.get<Miembro[]>(`${environment.urls.api}/backoffice/members`);
  }

  paginated(request: SearchRequest) {
    return this.http.post<PaginatedResponse<Miembro>>(`${environment.urls.api}/backoffice/members/all`, request);
  }

  contractsPaginated(searchRequest: SearchRequest) {
    return this.http.post<PaginatedResponse<ContratoRol>>(
      `${environment.urls.api}/backoffice/members/contracts`,
      searchRequest
    );
  }

  one(id: number) {
    return this.http.get<Miembro>(`${environment.urls.api}/backoffice/members/${id}`);
  }

  create(data: Miembro) {
    return this.http.post<Miembro>(
      `${environment.urls.api}/backoffice/members/create`,
      data
    );
  }

  update(id: number, data: Miembro) {
    return this.http.post<Miembro>(
      `${environment.urls.api}/backoffice/members/${id}/update`,
      data
    );
  }

  delete(id: number) {
    return this.http.delete<Miembro>(
      `${environment.urls.api}/backoffice/members/${id}/delete`
    );
  }
}
