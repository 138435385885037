import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Contrato, Documento, Revision } from '@shared/models/home.model';
import { UploadDocumento } from '@shared/models/generic.model';
import { EntregableContrato } from '@shared/models/entregable.model';
import { PaginatedResponse, SearchRequest } from '@azigrene/searchrequest';

@Injectable()
export class ContratoService {
  constructor(private http: HttpClient) {}

  list() {
    return this.http.get<Contrato[]>(`${environment.urls.api}/contracts`);
  }
  all(searchRequest: SearchRequest) {
    return this.http.post<PaginatedResponse<Contrato>>(`${environment.urls.api}/contracts`, searchRequest);
  }
  getAllNoPagination(searchRequest: SearchRequest = new SearchRequest()) {
    return this.http.post<Contrato[]>(`${environment.urls.api}/contractsNoPagination`, searchRequest);
  }

  getOne(id: number) {
    return this.http.get<Contrato>(`${environment.urls.api}/contracts/${id}`);
  }

  getAllDocuments(id: number, searchRequest: SearchRequest = new SearchRequest()) {
    return this.http.post<PaginatedResponse<Documento>>(`${environment.urls.api}/contracts/${id}/docs/list`, searchRequest);
  }

  getRevisionesById(contratoId: number, searchRequest: SearchRequest) {
    return this.http.post<PaginatedResponse<Revision>>(`${environment.urls.api}/contracts/${contratoId}/revisions`, searchRequest);
  }

  uploadDocument(idContrato: number, datos: UploadDocumento) {
    const input = new FormData();
    input.append('file', datos.file);
    input.append('nombre', datos.nombre);
    input.append('observaciones', datos.observaciones);
    if (datos.entregableId) input.append('entregableId', datos.entregableId);
    const req = new HttpRequest('POST', `${environment.urls.api}/contracts/${idContrato}/docs/create`, input);
    return this.http.request(req);
  }

  getEntregables(contratoId, searchRequest = new SearchRequest()) {
    return this.http.post<EntregableContrato[]>(`${environment.urls.api}/contracts/${contratoId}/deliverables`, searchRequest);
  }

  /** BACKOFFICe */

  create(id: number, data: Contrato) {
    return this.http.post<Contrato>(`${environment.urls.api}/backoffice/contracts/create`, data);
  }

  update(id: number, data: Contrato) {
    return this.http.post<Contrato>(`${environment.urls.api}/backoffice/contracts/${id}/update`, data);
  }

  delete(id: number) {
    return this.http.delete<Contrato>(`${environment.urls.api}/backoffice/contracts/${id}/delete`);
  }

  getAllBackoffice(searchRequest: SearchRequest = new SearchRequest()) {
    return this.http.post<Contrato[]>(`${environment.urls.api}/backoffice/contractsNoPagination`, searchRequest);
  }
}
