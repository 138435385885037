import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { AzNotifierService } from '@azigrene/components';
import { TranslocoService } from '@ngneat/transloco';
import { ActivatedRoute } from '@angular/router';
import { AzConfirmationModalService } from '@azigrene/components';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

export interface AssigneeModel {
  id?: any;
  username: string;
  img?: string;
}

@Component({
  selector: 'az-assignee-single',
  template: `
    <ng-container *transloco="let t; scope: 'azcomponents';">
      <div class="flex px-1 -mx-1 space-x group w-full min-h-8 group">
        <az-overlay *ngIf="_value" [template]="assigneeList" position="BOTTOM_LEFT"
                          originClass="cursor-pointer outline-none rounded-full" (onOpen)="clearSearch()">
          <az-assignees-circle-item [overlaps]="false" [text]="_value.username"></az-assignees-circle-item>
          <ng-template let-toppy1 #assigneeList>
            <div class="flex w-full items-center relative bg-gray-50 py-2">
              <svg class="w-4 h-4 text-gray-700 absolute left-0 ml-4" fill="currentColor" viewBox="0 0 20 20"
                   xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd"></path>
              </svg>
              <input #assigneeNameInput azAutoFocus type="text" class="w-full outline-none px-2 bg-transparent hover:bg-gray-200 ml-10 mr-2"
                     (keyup)="search($event.target['value'])" [(ngModel)]="searchValue"
                     (ngModelChange)="inputChange($event)" />
            </div>
            <div class="flex flex-col h-full max-h-64 overflow-y-auto divide-y border-t">
              <div *ngFor="let assignee of visibleTags; let i = index"
                   class="flex items-center space-x-2 px-2 py-1 hover:bg-gray-100  cursor-pointer"
                   (click)="select(assignee); search(''); $event.stopPropagation()">
                <az-assignees-circle-item [overlaps]="false" [text]="assignee.username"></az-assignees-circle-item>
                <span class="font-medium text-black"> {{ assignee.username }} </span>
              </div>
            </div>
          </ng-template>
        </az-overlay>
        <az-overlay *ngIf="!_value && editable" #dd [template]="assigneeList" position="BOTTOM_LEFT"
                          dropdownClass="flex-1"
                          class="flex flex-1 items-center"
                          [config]="{containerClass: 'z-40'}" (onOpen)="clearSearch()">
          <button
            class="z-10 text-xs bg-gray-100 text-gray-600  border-2 border-3 border-white flex items-center justify-center rounded-full uppercase h-8 w-8">
            <svg class="w-4 h-4 hover:cursor-pointer hover:text-primary" fill="none" stroke="currentColor"
                 viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"></path>
            </svg>
          </button>
          <ng-template let-toppy1 #assigneeList>
            <div class="flex w-full items-center relative bg-gray-50 py-2">
              <svg class="w-4 h-4 text-gray-700 absolute left-0 ml-4" fill="currentColor" viewBox="0 0 20 20"
                   xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd"></path>
              </svg>
              <input #assigneeNameInput azAutoFocus type="text"
                     class="w-full outline-none px-2 bg-transparent hover:bg-gray-200 ml-10 mr-2"
                     (keyup)="search($event.target['value'])" [(ngModel)]="searchValue"
                     (ngModelChange)="inputChange($event)" />
            </div>
            <div class="flex flex-col h-full max-h-64 overflow-y-auto divide-y border-t">
              <div *ngFor="let assignee of visibleTags; let i = index"
                   class="flex items-center space-x-2 px-2 py-1 hover:bg-gray-100  cursor-pointer"
                   (click)="select(assignee); search(''); $event.stopPropagation()">
                <az-assignees-circle-item [overlaps]="false" [text]="assignee.username"></az-assignees-circle-item>
                <span class="font-medium text-black"> {{ assignee.username }} </span>
              </div>
            </div>
          </ng-template>
        </az-overlay>
      </div>
    </ng-container>
  `,
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AssigneeSingleComponent),
      multi: true
    }
  ]
})
export class AssigneeSingleComponent implements OnInit {

  @Input() editable = false;
  @Input() assigneeListObservable: () => Observable<AssigneeModel[]>;
  @Input() assigneeUnassignObservable: (assignee: AssigneeModel) => Observable<AssigneeModel>;
  @Input() assigneeAssignObservable: (assignee: AssigneeModel) => Observable<AssigneeModel>;

  @Output() delete = new EventEmitter<AssigneeModel>();

  disabled = false;
  assignees: AssigneeModel[] = [];
  _value: AssigneeModel;
  visibleTags: AssigneeModel[] = [];
  _toAssign: AssigneeModel[];
  value: AssigneeModel;
  showCreate = false;
  searchValue: string;
  editing = false;


  onChange = (_: any) => {
  };
  onTouch = () => {
  };

  constructor(private notifierService: AzNotifierService,
    private translateService: TranslocoService,
    private activatedRoute: ActivatedRoute,
    private confirmationService: AzConfirmationModalService) {
  }

  ngOnInit(): void {
    this.assigneeListObservable().subscribe((et: AssigneeModel[]) => {
      this.assignees = et;
      this.populateValue(this.value);
    });
  }

  search(event): void {
    if (event === '') {
      this.visibleTags = this._toAssign;
    } else {
      this.visibleTags = this._toAssign.filter(e => (e.username.toLowerCase().includes(event.toLowerCase())));
    }
  }

  select(assignee: AssigneeModel): void {
    if (this._value?.username !== assignee.username) {
      this._value = assignee;
      if (this.assigneeAssignObservable) {
        this.assigneeAssignObservable(assignee);
      }

      this.save();
    }
  }

  remove(assignee: AssigneeModel): void {
    this._value = null;
    if (this.assigneeUnassignObservable) {
      this.assigneeUnassignObservable(assignee);
    }

    this.save();
  }

  clearSearch(): void {
    this.searchValue = '';
    this.inputChange(this.searchValue);
    this.search(this.searchValue);
  }

  inputChange(event): void {
    if (this.assignees.map(x => x.username).filter(y => y === event || y.toLowerCase() === event).length === 0) {
      if (event === '') {
        this.showCreate = false;
      } else {
        this.showCreate = true;
      }

      return;
    }

    this.showCreate = false;
  }

  emitValue(obj: any): void {
    this._value = obj;
    this.onChange(this._value);
  }

  save(): void {
    this.updateToAssign();
    this.emitValue(this._value);
  }

  updateToAssign(): void {
    this._toAssign = this.assignees.filter(assignee => this._value?.id !== assignee.id);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.value = obj;
    this.populateValue(obj);
  }

  private populateValue(value) {
    this._value = this.assignees.find(assignee => value.id === assignee.id);
    this.updateToAssign();
  }

}
