import {Injectable} from '@angular/core';
import {Revision} from '@shared/models/home.model';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {PaginatedResponse, SearchRequest} from '@azigrene/searchrequest';
import {EstadoGeneralModel} from '../pages/general-status/estado';
import {Observable} from "rxjs";
import {
  ChangeTagResult
} from "../pages/general-status/dialogs/cambiar-etiquetas-dialog/cambiar-etiquetas-dialog.component";

@Injectable()
export class GeneralService {
  base = 'general-status';

  constructor(private http: HttpClient) {
  }

  all(searchRequest = new SearchRequest()) {
    return this.http.post<PaginatedResponse<EstadoGeneralModel>>(`${environment.urls.api}/${this.base}/lastRevision`, searchRequest);
  }

  allRevisions(searchRequest = new SearchRequest()) {
    return this.http.post<PaginatedResponse<Revision>>(`${environment.urls.api}/${this.base}/all-revisions`, searchRequest);
  }

  search(searchRequest: SearchRequest): Observable<EstadoGeneralModel[]> {
    return this.http.post<EstadoGeneralModel[]>(`${environment.urls.api}/${this.base}/search`, searchRequest);
  }

  allTagNamesNoRepeat(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.urls.api}/${this.base}/tagNamesNoRepeat`);
  }

  export(searchRequest = new SearchRequest()) {
    return this.http.post(`${environment.urls.api}/${this.base}/exportRevisions`, searchRequest, {
      responseType: 'blob',
      observe: 'response'
    });
  }

  exportHistorico(searchRequest = new SearchRequest()) {
    return this.http.post(`${environment.urls.api}/${this.base}/export-all-revisions`, searchRequest, {
      responseType: 'blob',
      observe: 'response'
    });
  }

  accionMasiva(searchRequest: SearchRequest, accion, data: any) {
    return this.http.post(`${environment.urls.api}/${this.base}/accion-masiva`, {
      searchRequest,
      accion,
      data
    });
  }

  accionLista(ids: number[], accion, data: any) {
    return this.http.post(`${environment.urls.api}/${this.base}/accion-lista`, {ids, accion, data});
  }

  changeTags(tareaIds: number[], etiquetas: string[], sr: SearchRequest, add: boolean): Observable<ChangeTagResult> {
    return this.http.post<ChangeTagResult>(`${environment.urls.api}/${this.base}/changeTags`, {tareaIds, etiquetas, sr, add});
  }
}
