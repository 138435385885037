import { Usuario, LoginResponse } from 'src/app/shared/models/auth.model';

export class LoginAction {
    static readonly type = '[Auth] Login';
    constructor(public readonly payload?: {email: string, password: string}) {}
}
export class LoginSuccessAction {
    static readonly type = '[Auth] LoginSuccessAction';
    constructor(public readonly payload?: LoginResponse) {}
}
export class LoginErrorAction {
    static readonly type = '[Auth] LoginErrorAction';
    constructor(public readonly payload?: any) {}
}

export class LogoutAction {
    static readonly type = '[Auth] Logout';
}
export class LogoutSuccessAction {
    static readonly type = '[Auth] LogoutSuccessAction';
}
export class LogoutErrorAction {
    static readonly type = '[Auth] LogoutErrorAction';
    constructor(public readonly payload?: any) {}
}




