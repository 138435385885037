import {take} from 'rxjs/operators';
import {Errors} from '@shared/models/error.model';
import {AbstractBackendService, AbstractCRUDService} from '@shared/interfaces/service-classes';
import {AzConfirmationModalService, AzModalConfig, AzModalRef, AzNotifierService, ModalBaseComponent} from '@azigrene/components';
import {TranslocoService} from '@ngneat/transloco';
import {Identificable} from '@shared/interfaces/model-interfaces';
import {FormGroup} from '@angular/forms';
import {Directive, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';

@Directive()
export abstract class BasicEditDirective<T extends Identificable, TCR, TUR extends Identificable> extends ModalBaseComponent implements OnInit, OnDestroy {

  form: FormGroup;
  item: T;
  itemId: number;
  isEditMode = false;
  isItemRetrieved = false;
  subscriptions$ = new Subscription();
  isSaving = false;

  protected constructor(
    private _config: AzModalConfig,
    private _ref: AzModalRef,
    private _service: AbstractBackendService<T, TCR, TUR, undefined> | AbstractCRUDService<T, TCR, TUR, undefined>,
    private _notifierService: AzNotifierService,
    private _translateService: TranslocoService,
    private _confirmationService: AzConfirmationModalService
  ) {
    super(_config, _ref);
  }

  abstract getItemId(): number;
  abstract initForm(): void;
  abstract patchValue(): void;

  fillSelects(): void {
    return;
  }
  setFormValuesBeforeSave(): void {
    return;
  }
  onRetrieveDataSuccessful(): void {
    return;
  }
  getServiceArgs(): any {
    return;
  }

  ngOnInit(): void {
    this.itemId = this.getItemId();
    this.initForm();
    this.fillSelects();
    this.retrieveData();
  }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }

  retrieveData(): void {
    if (this.itemId) {
      this._service
        .get(this.itemId, this.getServiceArgs())
        .pipe(take(1))
        .subscribe((item: T) => {
          if (item) {
            this.item = item;
            this.isEditMode = true;
            this.patchValue();
          }

          this.isItemRetrieved = true;
          this.onRetrieveDataSuccessful();
        });
    }

    this.isItemRetrieved = true;
  }

  save(): void {
    this._notifierService.clear();
    this.setFormValuesBeforeSave();
    this.isSaving = true;
    if (this.form.get('id').value) {
      this.update(this.form.value);
    } else {
      this.create(this.form.value);
    }
  }

  update(item: TUR): void {
    this._service
      .update(item, this.getServiceArgs())
      .pipe(take(1))
      .subscribe(
        (item: any) => {
          if (item?.resultado?.errores?.length > 0) {
            this._notifierService.showAll(new Errors(item.resultado).errorMessages);
            this.isSaving = false;

            return;
          }

          this._notifierService.showInfoSuccess(this._translateService.translate('misc.notifier.dato-actualizado'));
          this._ref.close(true);
        },
        (error: ErrorEvent) => {
          this.isSaving = false;
          this._notifierService.showBackendError(error.error);
        }
      );
  }

  create(item: TCR): void {
    this._service
      .create(item)
      .pipe(take(1))
      .subscribe(
        (item: any) => {
          if (item?.resultado?.errores?.length > 0) {
            this._notifierService.showAll(new Errors(item.resultado).errorMessages);
            this.isSaving = false;

            return;
          }

          this._ref.close(item);
        },
        (error: ErrorEvent) => {
          this.isSaving = false;
          this._notifierService.showBackendError(error.error);
        }
      );
  }

  delete(): void {
    this._service
      .delete(this.form.get('id').value, this.getServiceArgs())
      .pipe(take(1))
      .subscribe(
        (result: boolean) => {
          if (result) {
            this._notifierService.showInfoSuccess(this._translateService.translate('misc.notifier.dato-eliminado'));
            this._ref.close(true);
          }
        },
        (error: ErrorEvent) => {
          this._notifierService.showBackendError(error.error);
        }
      );
  }

  confirmDelete(): void {
    this._confirmationService.open({
      showCancel: true,
      confirmationText: this.getConfirmationTextConfirmDelete(),
      okText: this._translateService.translate('actions.delete'),
      okButtonClass: 'danger',
      cancelText: this._translateService.translate('actions.cancel'),
      accept: () => {
        this.delete();
      }
    });
  }

  getConfirmationTextConfirmDelete(): string {
    return this._translateService.translate('misc.notifier.confirmar-eliminar');
  }

}
