<div class="modal-content">
  <div class="w-full relative py-4">
    <span class="color-text-tertiary">{{item?.contrato?.contratante?.nombre}}</span>
    <h2 azH2 class="truncate color-text-primary font-normal">{{ item?.nombre }}<p class="color-text-tertiary ml-2 font-normal">#{{ item?.id }}</p></h2>
  </div>
  <div class="flex space-x-3 py-4">
    <form [formGroup]="form" class="flex flex-1 flex-wrap">
      <div class="az-form-group w-full">
        <label
          for="frontoffice.contrato.tareas.detalle.titulo">{{ 'common.apartado-tarea.nombre' | transloco }}</label>
        <az-input-text id="frontoffice.contrato.tareas.detalle.titulo" inputStyleClass="!w-full" styleClass="!w-full"
                       formControlName="nombre"></az-input-text>
      </div>
      <div class="az-form-group w-full">
        <label
          for="frontoffice.contrato.tareas.detalle.descripcion">{{ 'common.descripcion' | transloco }}</label>
        <textarea class="border color-border-primary rounded-md p-2" rows="6"
                  id="frontoffice.contrato.tareas.detalle.descripcion" formControlName="descripcion"> </textarea>
      </div>
      <div class="az-form-group w-full">
        <label
          for="contrato.tareas.detalle.observaciones">{{ 'common.apartado-tarea.observaciones' | transloco }}</label>
        <textarea class="border color-border-primary rounded-md p-2" rows="6"
                  id="contrato.tareas.detalle.observaciones" formControlName="observaciones"> </textarea>
      </div>
      <div class="flex justify-end space-x-2 items-center w-full">
        <button azButton scheme="danger" (click)="confirmDelete()">{{ 'actions.delete' | transloco }}</button>
        <button azButton scheme="primary" [disabled]="form.pristine || form.invalid"
                (click)="updateOne()">{{ 'actions.save' | transloco }}</button>
      </div>
      <div class="w-full mt-8">
        <div class="flex items-center space-x-2 mb-2">
          <p azH3 class="font-semibold">{{ 'frontoffice.dialogs.revision.documents' | transloco }}</p>
          <div class="flex-1"></div>
          <button azButton (click)="openDialogoDocumento()" scheme="outline" size="sm">
            {{ 'actions.upload' | transloco }}
          </button>
        </div>
        <ul *ngIf="documentosRequest?.withData" class="!list-disc">
          <li *ngFor="let documento of documentosRequest.data">
            <div class="flex space-x-2 items-center">
              <button azButton scheme="link" (click)="downloadDoc(documento)">{{ documento.documento.nombre }}</button>
              <div class="flex-1"></div>
              <a class="underline color-text-secondary hover:text-primary" (click)="deleteDoc(documento)">
                {{ 'actions.delete' | transloco }}
              </a>
            </div>
          </li>
        </ul>
        <div *ngIf="documentosRequest?.empty"
             class="w-full p-4 border color-border-secondary color-bg-tertiary flex items-center justify-center rounded border-dashed">
          <span class="color-text-tertiary">{{ 'frontoffice.dialogs.revision.documents-empty' | transloco }}</span>
        </div>
      </div>
    </form>
    <div class="flex flex-col space-y-3" [style.width.px]="220">
      <div class="az-form-group w-full">
        <label>{{ 'common.responsable' | transloco }}</label>
        <div>
          <app-assignee-selector (updateSuccess)="retrieveData()" [contratoId]="item.contrato?.id" [tareaId]="item.id"
                                 [assignee]="{id: item.miembroResponsable?.id, username: item.responsable?.email}"></app-assignee-selector>
        </div>
      </div>
      <div class="az-form-group w-full">
        <label>{{ 'common.miembros' | transloco }}</label>
        <div>
          <app-assignees-selector (updateSuccess)="retrieveData()" [contratoId]="item.contrato?.id" [tareaId]="item.id"
                                  [assignees]="item.miembrosArray"></app-assignees-selector>
        </div>
      </div>
      <div class="az-form-group w-full">
        <label>{{ 'common.etiquetas' | transloco }}</label>
        <app-tag-selector (onUpdateSuccess)="retrieveData()" [tags]="item.etiquetasArray" [tareaId]="item.id"
                          [contratoId]="item.contrato?.id"></app-tag-selector>
      </div>
      <div class="az-form-group w-full">
        <label>{{ 'common.apartado-tarea.prioridad' | transloco }}</label>
        <app-priority-selector [prioridadId]="item.prioridad?.id" [tareaId]="item.id" tagClass="cursor-pointer h-8 leading-8 px-3" (onChange)="retrieveData()"
                               [contratoId]="item.contrato?.id"></app-priority-selector>
      </div>
      <div class="az-form-group w-full">
        <label>{{ 'common.apartado-tarea.periodicidad' | transloco }}</label>
        <app-periodicity-selector
          [periodicidadSeleccionada]="item.periodicidad"
          [expresionCron]="item.expresionCron"
          [tareaId]="item.id"
          [contratoId]="item.contrato?.id"
          [frecuencia]="item.frecuencia"
          (onUpdateSuccess)="changePeriodicidad($event)"
          (onChange)="retrieveData()"
        ></app-periodicity-selector>
      </div>
      <div class="az-form-group w-full" *ngIf="item.estadoDefectoId">
        <label>{{ 'common.apartado-tarea.estado-defecto' | transloco }}</label>
        <app-estado-selector class="flex-shrink-0" [statusId]="item.estadoDefectoId"
                             (onChange)="updateEstadoDefecto($event)"></app-estado-selector>
      </div>
    </div>
  </div>
  <div class="py-3">
    <div>
      <div class="border rounded-lg p-3">
        <div class="flex py-3 justify-between ">
          <ul [style.width.px]="100" class="list-none flex">
            <li role="button" *ngFor="let year of years"
                class="w-full flex justify-center items-center px-3 py-1.5 rounded-lg hover:color-bg-tertiary hover:cursor-pointer font-semibold border border-transparent {{activeYear == year ? 'color-bg-info color-border-primary' : ''}}"
                (click)="activeYear = year;revisionesRequest.load()">
              {{year}}
            </li>
          </ul>
          <button azButton scheme="invisible" (click)="revisionesRequest.load()">
            <az-icon icon="refresh"></az-icon>
          </button>
        </div>
        <app-contribution-chart [year]="this.activeYear" [data]="contributionData"
                                (dayClicked)="openDialogRevision($event)"></app-contribution-chart>
        <div class="flex justify-end">
          <div class="inline-flex space-x-[4px] p-1 py-2">
            <div *ngFor="let estado of (estadoRevisionConfig | keyvalue)" [title]="estado.value.label"
                 [style.width.px]="11" [style.height.px]="11" [style.border-color]="estado.value.borderColor"
                 [style.background-color]="estado.value.backgroundColor" [style.border-radius.px]="2"
                 class="border"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
