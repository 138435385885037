import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {Etiqueta, EtiquetaRequest} from '@shared/models/etiqueta.model';
import {AbstractBackendService} from '@shared/interfaces/service-classes';

@Injectable()
export class EtiquetaService extends AbstractBackendService<Etiqueta, EtiquetaRequest, EtiquetaRequest, any> {

  buildUrlBase(idContrato: number): string {
    return environment.api.etiqueta.usuario.replace('${idContrato}', idContrato.toString());
  }

}
