<az-notifier position="top-center"></az-notifier>
<div class="color-bg-primary h-full w-full">
  <div class="container mx-auto flex justify-center">
    <div class="max-w-xs w-full mx-auto">
      <div class="w-full flex flex-col items-center justify-center p-5">
        <span class="text-2xl mt-4">{{ 'login.iniciar-sesion' | transloco }}</span>
      </div>
      <form [formGroup]="loginForm" class="w-full rounded-md border p-5 mt-4 color-bg-tertiary">
        <div class="mb-4 flex flex-col">
          <label class="flex justify-between items-center mb-2" for="login.email">
            <span class="text-base">{{ 'login.email' | transloco }}<span style="color: red;"> *</span></span>
            <!--            <a class="az-button-link block text-xs text-center font-normal cursor-pointer" (click)="olvidastePassword()">{{ 'login.recuperar-password' | transloco }}</a>-->
          </label>
          <az-input-text scheme="clear" styleClass="w-full" id="login.email" inputId="login.email-input" type="text"
                         placeholder="jane@piedpipper.com" formControlName="email"></az-input-text>
        </div>
        <div class="mb-4 flex flex-col">
          <label class="flex justify-between items-center mb-2" for="login.password">
            <span class="text-base">{{ 'login.password' | transloco }}<span style="color: red;"> *</span></span>
            <!--            <a class="az-button-link block text-xs text-center font-normal cursor-pointer" (click)="olvidastePassword()">{{ 'login.recuperar-password' | transloco }}</a>-->
          </label>
          <az-input-text scheme="clear" styleClass="w-full" id="login.password" inputId="login.password-input"
                         placeholder="{{ 'login.password-placeholder' | transloco }}" type="password"
                         formControlName="password"></az-input-text>
        </div>
        <button [spinner]="loading" [disabled]="!loginForm.valid" azButton scheme="primary" id="login.log-in"
                (click)="enviarLogin()" styleClass="w-full justify-center">
          <span> {{ 'login.log-in' | transloco }}</span>
        </button>
      </form>
      <!--      <form class="color-bg-tertiary rounded-lg border p-12 w-full" [formGroup]="loginForm" (ngSubmit)="enviarLogin()">-->
      <!--        <div class="az-form-group">-->
      <!--          <az-input-text autocomplete="true" type="email" placeholder="{{ 'login.correo' | transloco }}" [inlineLeadingAddon]="true" [withLeadingAddon]="true" formControlName="email">-->
      <!--            <svg class="w-4 h-4" azInputLeadingAddon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">-->
      <!--              <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd" />-->
      <!--            </svg>-->
      <!--          </az-input-text>-->
      <!--        </div>-->
      <!--        <div *ngIf="!recuperacion" class="az-form-group mt-4">-->
      <!--          <az-input-text [inlineLeadingAddon]="true" [withLeadingAddon]="true" type="password" placeholder="{{ 'login.contrasena' | transloco }}" formControlName="password">-->
      <!--            <svg class="w-4 h-4" azInputLeadingAddon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">-->
      <!--              <path fill-rule="evenodd" d="M18 8a6 6 0 01-7.743 5.743L10 14l-1 1-1 1H6v2H2v-4l4.257-4.257A6 6 0 1118 8zm-6-4a1 1 0 100 2 2 2 0 012 2 1 1 0 102 0 4 4 0 00-4-4z" clip-rule="evenodd" />-->
      <!--            </svg>-->
      <!--          </az-input-text>-->
      <!--        </div>-->
      <!--        <div *ngIf="!recuperacion" class="mt-8 px-2">-->
      <!--          <button azButton level="primary" [block]="true" [disabled]="!loginForm.valid" type="submit">{{ 'login.entrar' | transloco }}</button>-->
      <!--        </div>-->
      <!--        <nz-spin *ngIf="loading" class="mt-4" nzSimple></nz-spin>-->
      <!--        <div *ngIf="recuperacion && !loading" class="mt-4 flex flex-col items-center">-->
      <!--          <button azButton level="primary" (click)="recuperarPassword()" [disabled]="loginForm.controls['email'].invalid" type="button">{{ 'passwordReset.boton.recuperar' | transloco }}</button>-->
      <!--          <button (click)="volver()" class="mt-2 button-link">Volver al login</button>-->
      <!--        </div>-->
      <!--      </form>-->
      <div *ngIf="recuperacion && !loading" class="mt-4">
        <button (click)="volver()"
                class="text-center text-accent-200">{{ 'passwordReset.boton.volver' | transloco }}</button>
      </div>
    </div>
  </div>
</div>
