import {TranslocoService} from '@ngneat/transloco';
import RandExp from 'randexp';
import {AfterViewInit, Directive, ElementRef, Inject, Input, OnChanges, OnInit, Renderer2, SimpleChanges} from '@angular/core';
import {AbstractControl, FormControlName, FormGroup} from '@angular/forms';
import {distinctUntilChanged} from 'rxjs/operators';
import {DOCUMENT} from '@angular/common';
import {Subscription} from 'rxjs';
import {InputErrorPipe} from '@shared/pipes/valid-input.pipe';

@Directive()
export abstract class AzFormValidation implements AfterViewInit {

  @Input('showFromErrors') showErrors = true;
  control: AbstractControl;

  constructor(private el: ElementRef, private translate: TranslocoService, private ren?: Renderer2, private fcn?: FormControlName) {}

  ngAfterViewInit(): void {

    this.control.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
      this.controlInputValidity();
    });
    this.control.statusChanges.subscribe(() => {
      this.controlInputValidity();
    });
    this.onViewInit();
  }

  controlInputValidity(): void {
    const controlId = this.getControlId(this.el.nativeElement);

    if (this.control && !this.control.pristine) {
      if (this.showErrors) {
        const id = `${controlId}_errors`;
        const parent = this.el.nativeElement.parentElement;
        let errorsDiv = document.getElementById(id);

        if (errorsDiv) {
          errorsDiv.remove();
        }

        errorsDiv = document.createElement('div');
        errorsDiv.id = `${controlId}_errors`;
        errorsDiv.classList.add('az-form-errors');
        if (this.control) {
          for (const errorEntry in this.control.errors) {
            if (!this.control.errors.hasOwnProperty(errorEntry)) {
              continue;
            }

            const errorSpan: HTMLParagraphElement = document.createElement('p');
            const text = this.translate.translate(`form-utils.errors.${errorEntry}`, {
              value: AzFormValidation.getTranslationValue(this.control.errors[errorEntry], errorEntry)
            });

            if (text !== `form-utils.errors.${errorEntry}`) {
              errorSpan.textContent = text;
              errorsDiv.appendChild(errorSpan);
            }
          }

          parent.appendChild(errorsDiv);
        }
      }
    }
  }

  getControlId(nativeElement: Element): string {
    let controlId = this.el.nativeElement.id || this.el.nativeElement.inputid;

    if (!controlId || controlId === '') {
      const inputEl = nativeElement.getElementsByTagName('INPUT')[0];

      controlId = inputEl.id;
    }

    return controlId;
  }

  abstract onViewInit();

  private static getTranslationValue(error: any, errorEntry: string) {
    switch (errorEntry) {
      case 'minlength':
        return error.requiredLength;
      case 'pattern':
        return new RandExp(error.requiredPattern).gen();
      case 'required-length':
        return error;
      case 'maxlength':
        return error.requiredLength;
      case 'max':
        return error.max;
      case 'min':
        return error.min;
      default:
        return true;
    }
  }

}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[azValid]'
})
export class AzValidDirective extends AzFormValidation implements OnInit, AfterViewInit {

  @Input('azValid') form: FormGroup;
  control: AbstractControl;

  constructor(private elementRef: ElementRef, private renderer: Renderer2, @Inject(DOCUMENT) private document, private formControlName: FormControlName, private translateService: TranslocoService) {
    super(elementRef, translateService, renderer, formControlName);
  }

  getControlId(nativeElement: Element): string {
    let controlId = this.elementRef.nativeElement.id || this.elementRef.nativeElement.inputid;

    if (!controlId || controlId === '') {
      const inputEl = nativeElement.getElementsByTagName('INPUT')[0];

      controlId = inputEl.id;
    }

    return controlId;
  }

  ngOnInit(): void {
    this.control = this.form.controls[this.formControlName.name];
  }

  onViewInit(): void {
    const validator = this.control.validator ? this.control.validator({} as AbstractControl) : null;

    if (validator && validator.required) {
      const controlId = this.getControlId(this.elementRef.nativeElement);

      const label = document.querySelector('[for="' + controlId + '"]');

      if (label) {
        if (label.childElementCount < 1) {
          const req = document.createElement('span');

          req.innerText = ' *';
          req.style.color = 'red';
          label.appendChild(req);
        }
      }
    }
  }

}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[azValidControl]'
})
export class AzValidControlDirective implements OnInit, AfterViewInit {

  @Input('azValidControl') control: AbstractControl;

  constructor(private elementRef: ElementRef, private renderer: Renderer2, @Inject(DOCUMENT) private document, private translateService: TranslocoService, private inputPipe: InputErrorPipe) {}

  ngAfterViewInit(): void {
    const validator = this.control.validator ? this.control.validator({} as AbstractControl) : null;

    if (validator && validator.required) {
      const controlId = this.getControlId(this.elementRef.nativeElement);

      const label = document.querySelector('[for="' + controlId + '"]');

      if (label) {
        if (label.childElementCount < 1) {
          const req = document.createElement('span');

          req.innerText = ' *';
          req.style.color = 'red';
          label.appendChild(req);
        }
      }
    }
  }

  getControlId(nativeElement: Element): void {
    let controlId = this.elementRef.nativeElement.id;

    if (!controlId || controlId === '') {
      const inputEl = nativeElement.getElementsByTagName('INPUT')[0];

      controlId = inputEl.id;
    }

    return controlId;
  }

  ngOnInit(): void {
    this.control.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
      const errors = [];

      if (this.control && !this.control.pristine) {
        if (this.control.hasError('minlength')) {
          errors.push(this.translateService.translate('validaciones.minLength', {value: this.control.getError('minlength').requiredLength}));
        }

        if (this.control.hasError('maxlength')) {
          errors.push(this.translateService.translate('validaciones.maxLength', {value: this.control.getError('maxlength').requiredLength}));
        }

        if (this.control.hasError('required')) {
          errors.push(this.translateService.translate('validaciones.requerido'));
        }

        const errorsElement = this.document.getElementById(this.control + '-errors');

        if (errorsElement) {
          this.renderer.removeChild(errorsElement.parentNode, errorsElement);
        }

        if (errors.length > 0) {
          const child = document.createElement('div');

          child.setAttribute('id', this.elementRef.nativeElement.id + '-errors');
          for (const error of errors) {
            const errorChild = document.createElement('small');

            errorChild.innerHTML = this.inputPipe.transform(error);
            errorChild.className = ' text-danger-400';
            this.renderer.appendChild(child, errorChild);
          }

          this.renderer.appendChild(this.elementRef.nativeElement.parentNode, child);
        }
      }
    });
  }

}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[azValidCondition]'
})
export class AzValidConditionDirective implements AfterViewInit, OnChanges {

  @Input('azValidCondition') condition: boolean;
  @Input('azValidRequiredLabel') requiredLabel = true;
  @Input('azValidAffectsTo') form: FormGroup;

  _formSub: Subscription;

  constructor(private elementRef: ElementRef, private renderer: Renderer2, @Inject(DOCUMENT) private document) {}

  ngAfterViewInit(): void {
    this.checkValid();
    if (this.form) {
      this._formSub = this.form.valueChanges.subscribe(() => {
        this.checkValid();
      });
    }
  }

  checkValid(): void {
    const controlId = this.getControlId(this.elementRef.nativeElement);
    const label = document.querySelector('[for="' + controlId + '"]');
    const input: HTMLElement = document.getElementById(controlId);

    if (label && this.requiredLabel) {
      if (label.childElementCount < 1) {
        const req = document.createElement('span');

        req.innerText = ' *';
        req.style.color = 'red';
        label.appendChild(req);
      }
    }

    if (!this.condition) {
      if (input) {
        input.classList.remove('ng-valid');
        input.classList.add('ng-invalid');
      }

      if (this.form) {
        const err = {[controlId]: true, ...this.form.errors};

        this.form.setErrors(err);
      }
    } else {
      if (input) {
        input.classList.add('ng-valid');
        input.classList.remove('ng-invalid');
      }

      if (this.form && this.form.errors) {
        const err = this.form.errors;

        delete err[controlId];
        this.form.setErrors(Object.keys(err).length ? err : null);
      }
    }
  }

  getControlId(nativeElement: Element): any {
    let controlId = this.elementRef.nativeElement.id;

    if (!controlId || controlId === '') {
      const inputEl = nativeElement.getElementsByTagName('INPUT')[0];

      controlId = inputEl?.id;
    }

    return controlId;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.checkValid();
  }

}
