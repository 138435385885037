import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {AbstractAPIService} from '@shared/interfaces/service-classes';
import {Adjudicatario} from '@shared/models/adjudicatario.model';
import {Observable} from 'rxjs';

@Injectable()
export class AdjudicatarioService extends AbstractAPIService {

  buildUrlBase(): string {
    return environment.api.adjudicatario.usuario;
  }

  getAll(): Observable<Adjudicatario[]> {
    return this.getHttp().get<Adjudicatario[]>(this.buildUrlBase() + '/all');
  }

}
