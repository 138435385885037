import { Injectable, LOCALE_ID, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { TokenInterceptor } from '@store/interceptors/token.interceptor';
import { AppRoutingModule } from './app.routes';
import { AppComponent } from './app.component';
import { Translation, TRANSLOCO_LOADER, TranslocoLoader, TranslocoModule } from '@ngneat/transloco';
import localeES from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { LoginComponent } from './pages/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@store/store.module';
import { PasswordService } from './service/password.service';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { Store } from '@ngxs/store';
import { HomeState } from '@store/home/home.store';
import { PIPES_SETTINGS_LOADER, PipesLocaleSettingsLoader } from '@azigrene/pipes';
import { AzComponentsModule } from '@azigrene/components';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AzDataManagerModule } from '@azigrene/data-manager';
import { SharedModule } from '@shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

registerLocaleData(localeES);

@Injectable()
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {
  }

  getTranslation(lang: string) {
    return this.http.get<Translation>(`./assets/i18n/${lang}.json`);
  }
}

@Injectable({ providedIn: 'root' })
export class PipesSettingsLoader implements PipesLocaleSettingsLoader {
  constructor(private store: Store) {
  }

  getSettings() {
    return this.store.select(HomeState.accountSettings);
  }
}

@NgModule({
  declarations: [AppComponent, LoginComponent, ChangePasswordComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    StoreModule,
    AppRoutingModule,
    AzDataManagerModule.forRoot({
      tableSaveStatus: true,
      tableStorageKey: 'fiscaliza-datamanager'
    }),
    SharedModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
    { provide: PIPES_SETTINGS_LOADER, useClass: PipesSettingsLoader },
    PasswordService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
