<ng-container *ngIf="!onlyActive"
  ><div class="flex flex-col md:flex-row  h-auto md:h-32">
    <div
      *ngFor="let estado of estados"
      class="flex flex-col h-24 md:flex-row flex-no-shrink items-center justify-center relative md:h-full flex-grow"
    >
      <div class="border-l-2 md:border-b-2  w-px md:h-px h-full md:w-full border-dotted"></div>
      <div
        class="rounded-full flex-no-shrink mx-2 flex items-center justify-center"
        [class.w-12]="status != estado.id"
        [class.h-12]="status != estado.id"
        [class.bg-gray-100]="status != estado.id"
        [class.w-16]="status == estado.id"
        [class.h-16]="status == estado.id"
        [class.bg-primary-500]="status == estado.id"
      >
        <span [ngSwitch]="estado.id">
          <div class="flex" *ngSwitchCase="1">
            <az-icon
              icon="time"
              [iconClass]="estado.id == status ? 'color-icon-primary' : ''"
            ></az-icon>
          </div>
          <div class="flex" *ngSwitchCase="2">
            <az-icon
              icon="play"
              [iconClass]="estado.id == status ? 'color-icon-primary' : ''"
            ></az-icon>
          </div>
          <div class="flex" *ngSwitchCase="3">
            <az-icon
              icon="check"
              [iconClass]="estado.id == status ? 'color-icon-primary' : ''"
            ></az-icon>
          </div>
          <div class="flex" *ngSwitchCase="4">
            <az-icon
              icon="thumb-up"
              [iconClass]="estado.id == status ? 'color-icon-primary' : ''"
            ></az-icon>
          </div>
          <div class="flex" *ngSwitchCase="5">
            <az-icon
              icon="thumb-down"
              [iconClass]="estado.id == status ? 'color-icon-primary' : ''"
            ></az-icon>
          </div>
          <div class="flex" *ngSwitchCase="6">
            <az-icon
              icon="lock-closed"
              [iconClass]="estado.id == status ? 'color-icon-primary' : ''"
            ></az-icon>
          </div>
          <div *ngSwitchDefault></div>
        </span>
      </div>
      <div class="border-l-2 md:border-b-2  w-px md:h-px h-full md:w-full border-dotted"></div>

      <div
        class="absolute pin-l md:pin-none md:pin-b ml-4 md:ml-0 tracking-medium"
        [class.text-primary-500]="status == estado.id"
        [class.font-bold]="status == estado.id"
        [class.text-sm]="status != estado.id"
      >
        {{ estado.label }}
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="onlyActive">
  <div class="flex flex-col items-start w-full">
    <div class="flex mb-1">
      <span class="font-semibold text-primary-400 uppercase">{{ 'home.revision.estados.' + (status ? status : 1) | transloco }}</span>
    </div>
    <div class="flex w-full h-2 shadow-inner bg-gray-50 rounded-full ">
      <div class="flex  rounded-full bg-warn-400 w-1/4" *ngIf="status == 1">
        <!-- <svg-icon icon="time.svg" class="azicon mr-2"></svg-icon> -->
      </div>
      <div class="flex  rounded-full bg-primary-400 w-1/2" *ngIf="status == 2">
        <!-- <svg-icon icon="wrench.svg" class="azicon mr-2"></svg-icon> -->
      </div>
      <div class="flex  rounded-full bg-success-300 w-3/4" *ngIf="status == 3">
        <!-- <svg-icon icon="inbox-check.svg" class="azicon mr-2"></svg-icon> -->
      </div>
      <div class="flex  rounded-full bg-success-300 w-full" *ngIf="status == 4">
        <!-- <svg-icon icon="thumbs-up.svg" class="azicon mr-2"></svg-icon> -->
      </div>
      <div class="flex  rounded-full bg-danger-400 w-full" *ngIf="status == 5">
        <!-- <svg-icon icon="thumbs-down.svg" class="azicon mr-2"></svg-icon> -->
      </div>
      <div class="flex  rounded-full bg-gray-400 w-full" *ngIf="status == 6">
        <!-- <svg-icon icon="close-outline.svg" class="azicon mr-2"></svg-icon> -->
      </div>
    </div>
  </div>
</ng-container>
