import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {Contratante} from "@shared/models/contratante.model";
import {AbstractAPIService} from "@shared/interfaces/service-classes";
import {Observable} from "rxjs";

@Injectable()
export class ContratanteService  extends AbstractAPIService {

  buildUrlBase(): string {
    return environment.api.contratante.usuario;
  }

  getAll(): Observable<Contratante[]> {
    return this.getHttp().get<Contratante[]>(this.buildUrlBase() + '/all');
  }

}
