import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { PaginatedResponse, SearchRequest } from '@azigrene/searchrequest';
import { EntregableContrato } from '@shared/models/entregable.model';

@Injectable()
export class EntregableContratoService {
  constructor(private http: HttpClient) {}

  all(idContrato: number, searchRequest: SearchRequest) {
    return this.http.post<PaginatedResponse<EntregableContrato>>(`${environment.urls.api}/contracts/${idContrato}/deliverables`, searchRequest);
  }

  one(id: number, idContrato: number) {
    return this.http.get<EntregableContrato>(`${environment.urls.api}/contracts/${idContrato}/deliverables/${id}`);
  }

  create(idContrato: number, data: EntregableContrato) {
    return this.http.post<EntregableContrato>(`${environment.urls.api}/contracts/${idContrato}/deliverables/create`, data);
  }

  update(id: number, idContrato: number, data: EntregableContrato) {
    return this.http.post<EntregableContrato>(`${environment.urls.api}/contracts/${idContrato}/deliverables/${id}/update`, data);
  }
  delete(id: number, idContrato: number) {
    return this.http.delete<EntregableContrato>(`${environment.urls.api}/contracts/${idContrato}/deliverables/${id}/delete`);
  }
}
