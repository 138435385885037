import {EnvironmentI} from "@env/environment.model";
import {apiEndpoints} from "@config/app.config";

export const environment: EnvironmentI = {
  production: true,
  urls: {
    api: window['env']['apiUrl'] || 'http://51.91.48.191:5050',
    web: window['env']['webUrl'] || 'http://localhost:4200',
  },
  idioma: 'es',
  api: apiEndpoints
};
