import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { first, flatMap, catchError } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { AuthState } from '../auth/auth.store';
import { LogoutAction } from '../auth/auth.actions';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private store: Store) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.select(AuthState.getToken).pipe(
      first(),
      flatMap(token => {
        const authReq = token
          ? token
            ? request.clone({
              setHeaders: { Authorization: 'Bearer ' + token }
            })
            : request
          : request;
        return next.handle(authReq);
      }),
      catchError((response: any) => {
        if (response instanceof HttpErrorResponse && response.status === 401) {
          this.store.dispatch(new LogoutAction());
        }
        return throwError(response);
      })
    );
  }
}
