import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-form-file',
  templateUrl: './form-file.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormFileComponent),
      multi: true,
    },
  ],
})
export class FormFileComponent implements ControlValueAccessor {

  @Input() disabled = false;
  @Input() type = 'file';
  @Input() alt = '';
  @Input() value: File;
  @Input() returnAsFile = false;
  @Input() showPreview = false;
  @Input() requiredType: ('text/plain' | 'application/vnd.ms-excel' | 'application/pdf' | 'image/jpeg' | 'image/png' | 'image/' | 'file/' | 'spreadsheet')[] = ['image/', 'file/'];
  @Output() getTextCSV = new EventEmitter();
  @ViewChild('inputElement') inputElement: ElementRef;
  dragging = false;
  errorFile = false;

  onChange: (v: File) => unknown = () => {
    return;
  };

  onTouch: () => unknown = () => {
    return;
  };

  writeValue(value: File): void {
    if (value) {
      this.value = value;
    } else {
      this.value = null;
    }
  }

  registerOnChange(fn: () => unknown): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => unknown): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChangeFile(files: FileList): void {
    if (files.length > 0) {
      const file: File = files[0];
      const tiposString = this.requiredType ? this.requiredType.map((type) => type.toString()) : [];
      let tipoAceptado = false;

      tiposString.forEach((tipo) => {
        if (file.type.includes(tipo) || file.type.match(tipo)) {
          tipoAceptado = true;
          const reader: FileReader = new FileReader();

          reader.readAsText(file);
          reader.onload = () => {
            this.getTextCSV.emit(reader.result);
          };
        }
      });
      if (!tipoAceptado) {
        this.errorFile = true;
        this.returnAsFile = false;
        this.value = null;
        throw new TypeError('El formato de fichero no es valido');
      } else {
        this.returnAsFile = true;
        this.errorFile = false;
      }

      if (this.returnAsFile) {
        this.value = files[0];
        this.onChange(this.value ? this.value : null);
      }
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.dragging = true;
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.onChangeFile(event.dataTransfer.files);
    this.dragging = false;
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.dragging = false;
  }

  onDragEnd(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.dragging = false;
  }

  onChangeInputFile(event: Event): void {
    const element: HTMLInputElement = event.target as HTMLInputElement;

    this.onChangeFile(element.files);
  }

}
