import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {Tarea, TareaCreateRequest, TareaUpdateRequest} from '@shared/models/tarea.model';
import {AbstractBackendTareaService} from '@shared/interfaces/tarea-service-interface';


@Injectable()
export class TareaService extends AbstractBackendTareaService<Tarea, TareaCreateRequest, TareaUpdateRequest, any> {

  buildUrlBase(contratoId: number): string {
    return environment.api.tarea.usuario.replace('${contratoId}', contratoId.toString());
  }

}
