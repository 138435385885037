import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { PaginatedResponse, SearchRequest } from '@azigrene/searchrequest';
import { ElementoActuacion } from '@shared/models/home.model';

@Injectable()
export class ElementoActuacionService {
  constructor(private http: HttpClient) {
  }

  all(idContrato: number, searchRequest: SearchRequest) {
    return this.http.post<PaginatedResponse<ElementoActuacion>>(
      `${environment.urls.api}/contracts/${idContrato}/actuators/all`,
      searchRequest
    );
  }

  one(id: number, idContrato: number) {
    return this.http.get<ElementoActuacion>(`${environment.urls.api}/contracts/${idContrato}/actuators/${id}`);
  }

  create(idContrato: number, data: ElementoActuacion) {
    return this.http.post<ElementoActuacion>(
      `${environment.urls.api}/contracts/${idContrato}/actuators/create`,
      data
    );
  }

  update(id: number, idContrato: number, data: ElementoActuacion) {
    return this.http.post<ElementoActuacion>(
      `${environment.urls.api}/contracts/${idContrato}/actuators/${id}/update`,
      data
    );
  }

  delete(id: number, idContrato: number) {
    return this.http.delete<ElementoActuacion>(
      `${environment.urls.api}/contracts/${idContrato}/actuators/${id}/delete`
    );
  }
}
