import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { PrioridadTarea } from '@shared/models/home.model';
import { TareaActions } from '../../../../modules/frontoffice/actions/TareaActions';
import { TareaService } from '../../../../modules/frontoffice/services/tarea.service';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-priority-selector',
  template: `
    <az-overlay #dd class="flex my-1" [template]="tpl">
      <app-tag-prioridad-tarea *ngIf="_prioridadActivaId" [tagClass]=tagClass
                               [priorityId]="_prioridadActivaId">
      </app-tag-prioridad-tarea>
      <div *ngIf="!_prioridadActivaId" [ngClass]="tagClass"
           class="px-2 rounded-sm bg-gray-100 flex items-center justify-center">
        <svg class="w-4 h-4 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24"
             xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
        </svg>
      </div>
      <ng-template #tpl>
        <app-priority-dropdown [dropdown]="dd" [prioridadId]="prioridadId" [tareaId]="tareaId" (onChange)="onChange.emit($event)"
                               [contratoId]="contratoId" ></app-priority-dropdown>
      </ng-template>
    </az-overlay>
  `,
  styles: []
})
export class PrioritySelectorComponent implements OnInit, OnChanges {
  @Input() contratoId: number;
  @Input() tareaId: number;
  @Input() prioridad: PrioridadTarea;
  @Input() prioridadId: number;
  @Input() tagClass: string;

  @Output() onChange = new EventEmitter();
  @Output() onUpdateSuccess = new EventEmitter();

  prioridades = [
    { id: 1, label: 'Baja', class: 'bg-green-200 text-black' },
    { id: 2, label: 'Media', class: 'bg-yellow-200 text-black' },
    { id: 3, label: 'Alta', class: 'bg-red-200 text-black' },
    { id: 4, label: 'Urgente', class: 'bg-red-600 text-white' }
  ];
  _prioridadActivaId: number;

  constructor(private tareaService: TareaService) {
  }

  ngOnInit() {
    this._prioridadActivaId = this.prioridad ? this.prioridad.id : this.prioridadId;
  }

  change(p: { id: number; label: string; class: string }) {
    if (p) {
      this.onChange.emit(p.id);
      this._prioridadActivaId = p.id;
      this.updatePriority();
    } else {
      this.onChange.emit(null);
      this._prioridadActivaId = null;
      this.updatePriority();
    }
  }

  updatePriority() {
    this.tareaService.accionLista( [this.tareaId], TareaActions.SET_PRIORITY, { prioridadId: this._prioridadActivaId }, this.contratoId).pipe(take(1)).subscribe(n => {
      if (n) this.onUpdateSuccess.emit();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.prioridadId) {
      this._prioridadActivaId = changes.prioridadId.currentValue;
    }
  }
}
