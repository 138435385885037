import { Component, Input, OnInit } from '@angular/core';
import { DateUtils } from '@azigrene/utils';
import cronstrue from 'cronstrue/i18n';
import { TranslocoService } from '@ngneat/transloco';
import { isToday, isTomorrow, startOfDay, parseISO } from 'date-fns';
import {PeriodicidadEnum} from "@shared/enum/enums";

@Component({
  selector: 'app-fecha-entrega',
  template: `
    <div class="inline-flex items-center px-1 {{small ? 'text-xs' : ''}} space-x-1 {{dayClass}}"
    >
      <span>{{fechaEntrega | amCalendar:{
        sameDay: '[Hoy]',
        nextDay: '[Mañana]',
        nextWeek: 'dddd',
        lastDay: '[Ayer]',
        lastWeek: '[Pasado] dddd',
        sameElse: 'DD/MM/YYYY'
      }
        }}</span>
      <svg class='w-4 h-4' [pTooltip]='cron ? cronDescription : periodicidad' *ngIf="periodicidad !== periodicidadEnum.PUNTUAL"
           xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
        <path stroke-linecap='round' stroke-linejoin='round' stroke-width='2'
              d='M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15' />
      </svg>
    </div>
  `,
  styles: []
})
export class FechaEntregaComponent implements OnInit {
  @Input() estadoRevision: string;
  @Input() fechaEntrega: string;
  @Input() periodicidad: string;
  @Input() small = false;
  @Input() cron: string;
  cronstrue = cronstrue;
  cronDescription: string;

  isBefore = DateUtils.isBefore;
  today = new Date();
  tomorrow = new Date();

  isToday = false;
  isDue = false;
  periodicidadEnum = PeriodicidadEnum;
  dayClass = '';

  constructor(private translateService: TranslocoService) {
  }

  ngOnInit() {
    if (this.fechaEntrega) {
      if (DateUtils.isBefore(DateUtils.withoutTimezone(startOfDay(parseISO(this.fechaEntrega))), DateUtils.withoutTimezone(startOfDay(this.today)))) {
        this.isDue = true;
        this.dayClass = 'text-red-600';
      } else if (isToday(parseISO(this.fechaEntrega)) || isTomorrow(parseISO(this.fechaEntrega))) {
        this.isToday = true;
        this.dayClass = 'text-teal-500';
      }
    }
    if (this.cron) {
      this.cronDescription = cronstrue.toString(this.cron, {
        locale: this.translateService.getActiveLang(),
        use24HourTimeFormat: true
      });
    }
  }
}
