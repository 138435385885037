import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import { get } from 'lodash';
import {
  isBefore,
  isToday,
  isTomorrow,
  isAfter,
  isThisWeek,
  startOfWeek,
  addWeeks, parseISO
} from 'date-fns';

@Component({
  selector: 'app-events-list',
  template: `
    <div class='w-full flex-1 overflow-y-auto relative bg-gray-50 pb-8'>
      <ng-container *ngFor='let category of _cats'>
        <div class='inline-flex items-center sticky px-2 top-0 z-20 py-2 w-full bg-gray-50 border-b-2 pb-1'>
          <button azButton level='tertiary' size='sm' (click)='category.collapsed = !category.collapsed'>
            <svg *ngIf='category.collapsed' class='w-4 h-4' fill='none' stroke='currentColor' viewBox='0 0 24 24'
                 xmlns='http://www.w3.org/2000/svg'>
              <path stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M9 5l7 7-7 7'></path>
            </svg>
            <svg *ngIf='!category.collapsed' class='w-4 h-4' fill='none' stroke='currentColor' viewBox='0 0 24 24'
                 xmlns='http://www.w3.org/2000/svg'>
              <path stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'></path>
            </svg>
            <span class='font-medium text-black'>{{ category.label }}</span>
          </button>
        </div>
        <div class='space-y-2 pb-3 pt-2' *ngIf='!category.collapsed'>
          <ng-container *ngFor='let d of _data[category.key]'>
            <ng-container *ngTemplateOutlet='itemTemplate;context:{item:d}'>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
      <div class='flex items-center justify-center' *ngIf='(!dataCount || dataCount >= data.length+1)'>
        <button azButton (click)='loadMore()'>Cargar más ({{dataCount - data.length}})</button>
      </div>
    </div>
  `,
  styles: []
})
export class EventsListComponent implements OnInit, OnChanges {
  @HostBinding('class') class = 'overflow-y-auto flex flex-col';
  @Input() dateProperty: string;
  @Input() data: any[];
  @Input() dataCount: number;

  @Input() itemTemplate: TemplateRef<any>;
  @Output() onLoadMore = new EventEmitter();
  _data: { [date: string]: any[] };

  @Input() categories: {
    [id: string]: { func: (date: Date) => boolean, label: string, order: number }
  } = {
    past: {
      func: (date) => {
        return isBefore(date, new Date());
      },
      label: 'Vencidas',
      order: 1
    },
    today: {
      func: (date) => {
        return isToday(date);
      },
      label: 'Hoy',
      order: 2
    },
    tomorrow: {
      func: (date) => {
        return isTomorrow(date);
      },
      label: 'Mañana',
      order: 3
    },
    week: {
      func: (date) => {
        return isAfter(date, new Date()) && isThisWeek(date);
      },
      label: 'Esta semana',
      order: 4
    },
    next: {
      func: (date) => {
        return isAfter(date, startOfWeek(addWeeks(new Date(), 1)));
      },
      label: 'Proximas',
      order: 5
    }
  };
  _categories: { key: string, label: string, order: number }[] = [];
  _cats: { key: string, label: string, order: number, collapsed?: boolean }[] = [];

  constructor() {
  }

  ngOnInit() {
    this._categories = Object.entries(this.categories).map((value) => {
      return { key: value[0], label: value[1].label, order: value[1].order };
    });
    this._categories.sort((a, b) => a.order - b.order);
    this.processData(this.data);
  }

  getCategory(date: string) {
    for (const entry of Object.entries(this.categories)) {
      const key = entry[0];
      const value = entry[1];
      if (value.func(parseISO(date))) {
        return key;
      }
    }
    return null;
  }

  processData(data: any[]) {
    this._data = data.reduce((m, o) => {
      const date = get(o, this.dateProperty);
      const category = this.getCategory(date);
      if (m[category]) {
        m[category].push(o);
      } else {
        m[category] = [o];
      }
      return m;
    }, {});
    this._cats = this._categories.filter(v => Object.keys(this._data).includes(v.key));
  }

  loadMore() {
    this.onLoadMore.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.processData(changes.data.currentValue);
    }
  }

}
