import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {AbstractAPIService} from '@shared/interfaces/service-classes';
import {Observable} from "rxjs";
import {Subestado} from "../models/subestado.model";

@Injectable()
export class SubestadoService extends AbstractAPIService {

  buildUrlBase(): string {
    return environment.api.subestado.usuario;
  }

  findByEstadoIdAndEsquemaEstado(estadoId: number, esquemaEstados: string): Observable<Subestado[]> {
    return this.getHttp().post<Subestado[]>(this.buildUrlBase() + '/find-by-estado-id-and-schema', {estadoId, esquemaEstados});
  }

}
