import {Injectable} from '@angular/core';
import {AbstractAPIService} from '@shared/interfaces/service-classes';
import {TipoContrato} from '@shared/models/tipo-contrato.model';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';

@Injectable()
export class TipoContratoService extends AbstractAPIService {

  buildUrlBase(): string {
    return environment.api.tipoContrato.usuario;
  }

  getAll(): Observable<TipoContrato[]> {
    return this.getHttp().get<TipoContrato[]>(this.buildUrlBase() + '/all');
  }

}
