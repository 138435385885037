import {Identificable} from '@shared/interfaces/model-interfaces';
import {IBackendAccionMasivaService} from '@shared/interfaces/service-interfaces';
import {SearchRequest} from '@azigrene/searchrequest';
import {Observable} from 'rxjs';
import {EntregableTarea} from '@shared/models/entregable.model';
import {ResultadoValidacionModel} from '@shared/models/resultado-validacion.model';
import {AbstractBackendAccionMasivaService} from '@shared/interfaces/service-classes';
import {Tarea} from '@shared/models/tarea.model';
import {TareaActions} from '../../modules/frontoffice/actions/TareaActions';
import {HttpResponse} from '@angular/common/http';

export abstract class AbstractBackendTareaService<ItemModel extends Identificable, ItemCreateRequest, ItemUpdateRequest extends Identificable, BaseUrlArgsType>
  extends AbstractBackendAccionMasivaService<ItemModel, ItemCreateRequest,ItemUpdateRequest, BaseUrlArgsType, unknown> implements IBackendAccionMasivaService<ItemModel, ItemCreateRequest, ItemUpdateRequest, BaseUrlArgsType, unknown> {

  getEntregables(tareaId: number, contratoId?: number, searchRequest = new SearchRequest()): Observable<EntregableTarea[]> {
    return this.http.post<EntregableTarea[]>(
      this.buildUrlBase(contratoId) + `/${tareaId}/deliverables`,
      searchRequest
    );
  }

  exportPeriodicity(contratoId?: number): Observable<HttpResponse<Blob>> {
    return this.http.get(this.buildUrlBase(contratoId) + `/periodicity/export`, { responseType: 'blob', observe: 'response' });
  }

  importPeriodicity(file: unknown, contratoId?: number): Observable<ResultadoValidacionModel> {
    return this.http.post<ResultadoValidacionModel>(this.buildUrlBase(contratoId) + `/periodicity/import`, file);
  }

}
