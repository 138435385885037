<div class="flex flex-col">
  <div class="flex flex-wrap items-center justify-between p-4 bg-primary-dark" *ngIf="showHeader">
    <div class="flex flex-col flex-shrink-0 text-white items-start">
      <p class="text-white font-semibold tracking-wide text-2xl">{{ headerTitle }}</p>
      <p class="text-primary-lighter font-medium tracking-wide">{{ headerSubtitle }}</p>
    </div>
    <ng-content select="[azStepperHeaderRight]"></ng-content>
  </div>

  <nav class="steps">
    <ng-container *ngFor="let step of steps | keyvalue: originalOrder; let i = index">
      <div
        [class.active]="activeStep === step.key"
        *ngIf="!step.value.disabled"
        [attr.index]="i"
        (click)="step.value.canBeClicked ? (activeStep = step.key) : null; step.value.click ? step.value.click() : null"
        class="step-item"
      >
        <small class="step-index">{{ i + 1 }}</small>
        <span class="step-text">{{ step.value.title | transloco }}</span>
        <div class="step-indicator"></div>
      </div>
    </ng-container>
  </nav>
</div>
<div class="flex-1 overflow-y-auto">
  <ng-container [ngTemplateOutlet]="contentTemplate" [ngTemplateOutletContext]="{ activeStep: activeStep, steps: steps }"></ng-container>
</div>
<div *ngIf="showFooter" class="p-4 flex items-center justify-between flex-shrink-0 border-t">
  <button
    azButton
    level="secondary"
    [style.visibility]="isFirstStep() || (!previousEnabled && isLastStep()) ? 'hidden' : 'visible'"
    [disabled]="!previousEnabled || (steps[activeStep].previousEnabled ? !steps[activeStep].previousEnabled() : false)"
    (click)="onPreviousStep()"
  >
    <az-icon icon="chevron-left"></az-icon>
    <span>{{ steps[activeStep].previousStepText || previousStepText | transloco }}</span>
  </button>
  <button
    azButton
    level="primary"
    id="stepper.btn-next"
    [disabled]="!nextEnabled || (steps[activeStep].nextEnabled ? !steps[activeStep].nextEnabled() : false)"
    (click)="onNextStep()"
  >
    <span>{{ (isLastStep() ? ('misc.finalizar' | transloco) : steps[activeStep].nextStepText || nextStepText) | transloco }}</span>
    <az-icon icon="chevron-right"></az-icon>
  </button>
</div>
