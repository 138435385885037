import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import * as datefns from 'date-fns';
import {DateUtils} from "@shared/utils";
import moment from "moment";

export interface ContributionChartDataItem {
  date: Date;
  backgroundColor: string;
  borderColor: string;
  item: any;
}

@Component({
  selector: 'app-contribution-chart',
  templateUrl: './contribution-chart.component.html',
  styleUrls: ['./contribution-chart.component.scss']
})
export class ContributionChartComponent implements OnInit, OnChanges {
  @Input() data: ContributionChartDataItem[] = [];
  @Input() year: number = datefns.getYear(new Date());
  @Output() dayClicked: EventEmitter<any> = new EventEmitter<any>();
  weekdays = [1, 2, 3, 4, 5, 6, 0];

  mapOfWeekdays: { [id: number]: { date: Date, month: number, weekday: number, inRange: number, data: ContributionChartDataItem, firstMonday: boolean, weeksSpan: number }[] } = {};
  months: { span: number; firstDate: Date }[] = [];

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data || changes.year) {
      this.calculateData(changes.data?.currentValue || this.data);
    }
  }

  ngOnInit(): void {
    this.calculateData(this.data);
  }

  translateWeekday(weekday: number) {
    const dummyDate = new Date(2001, 0, weekday)
    const locale = new Intl.DateTimeFormat().resolvedOptions().locale

    return dummyDate.toLocaleDateString(locale, {weekday: 'short'})
  }

  private calculateData(data: ContributionChartDataItem[]) {
    if (!data || !data.length) data = [];
    data = data.sort((a, b) => datefns.compareAsc(a.date, b.date));
    const start = new Date(this.year, 0, 1);
    const end = new Date(this.year, 11, 31);
    const mapOfDates = data.reduce((m, o) => {
      const dateStr = moment(o.date).format('DDMMYYYY');
      if (!m[dateStr]) m[dateStr] = o;
      return m;
    }, {})
    const monthStarts: { [month: number]: { span: number, firstDate: Date } } = {};
    const mapOfWeeks = {1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 0: []};
    for (let i of DateUtils.datesBetween(datefns.startOfWeek(start, {weekStartsOn: 1}), datefns.endOfWeek(end, {weekStartsOn: 1}))) {
      const inRange = (datefns.isBefore(i, end) && datefns.isAfter(i, start)) || datefns.isSameDay(i, start) || datefns.isSameDay(i, end);
      const dateStr = moment(i).format('DDMMYYYY');
      mapOfWeeks[datefns.getDay(i)].push({
        date: i,
        month: datefns.getMonth(datefns.startOfWeek(i, {weekStartsOn: 1})),
        firstMonday: this.isFirstMonday(i),
        weeksSpan: datefns.differenceInWeeks(i, datefns.addWeeks(datefns.endOfMonth(i), 1)),
        weekday: datefns.getDay(i),
        inRange: inRange,
        data: mapOfDates[dateStr] || null
      });
      if (inRange) {
        if (!monthStarts[datefns.getMonth(i)]) monthStarts[datefns.getMonth(i)] = {span: 0, firstDate: i}
      }
    }
    Object.keys(monthStarts).forEach((value, index) => {
      if (index > 0) {
        monthStarts[index - 1].span = datefns.differenceInCalendarWeeks(monthStarts[index].firstDate, monthStarts[index - 1].firstDate, {weekStartsOn: 1})
      }
      if (index == Object.keys(monthStarts).length - 1) {
        monthStarts[index].span = datefns.differenceInCalendarWeeks(datefns.endOfYear(monthStarts[index].firstDate), monthStarts[index].firstDate, {weekStartsOn: 1})
      }
    })
    this.months = Object.values(monthStarts).sort((a, b) => datefns.compareAsc(a.firstDate, b.firstDate));
    Object.keys(mapOfWeeks).forEach(value => {
      mapOfWeeks[value].sort((a, b) => datefns.compareAsc(a, b))
    })

    this.mapOfWeekdays = mapOfWeeks;
  }

  isFirstMonday(date: Date) {
    const startOfMonth = datefns.startOfMonth(date);
    const firstSaturday = datefns.setDay(startOfMonth, 1, {weekStartsOn: datefns.getDay(startOfMonth)});
    return datefns.isSameDay(firstSaturday, date);
  }
}
