import {Component, OnInit, Input, OnChanges, SimpleChanges} from '@angular/core';
import {EstadoRevision} from '@shared/models/home.model';

export const EstadoRevisionConfig = {
  1: {
    label: 'Pendiente',
    backgroundClass: 'bg-orange-200',
    backgroundColor: '#fed7aa',
    borderClass: 'border-orange-300',
    borderColor: '#fdba74',
    backgroundInverseClass: 'bg-orange-50',
    iconClass: 'text-orange-700',
    icon: 'clock',
  },
  2: {
    label: 'En proceso',
    backgroundClass: 'bg-amber-200',
    backgroundColor: '#fef3c7',
    borderClass: 'border-amber-400',
    borderColor: '#fcd34d',
    backgroundInverseClass: 'bg-amber-50',
    iconClass: 'text-amber-500',
    icon: 'dots-circle-horizontal',
  },
  3: {
    label: 'Finalizada',
    backgroundClass: 'bg-teal-200',
    backgroundColor: '#5eead4',
    borderClass: 'border-teal-400',
    borderColor: '#14b8a6',
    backgroundInverseClass: 'bg-teal-50',
    iconClass: 'text-teal-500',
    icon: 'check-circle',
  },
  4: {
    label: 'Validada',
    backgroundClass: 'bg-green-200',
    backgroundColor: '#4ade80',
    borderClass: 'border-green-400',
    borderColor: '#22c55e',
    iconClass: 'text-green-500',
    backgroundInverseClass: 'bg-green-50',
    icon: 'shield-check',
  },
  5: {
    label: 'Rechazada',
    backgroundClass: 'bg-red-200',
    backgroundColor: '#fecaca',
    borderClass: 'border-red-400',
    borderColor: '#f87171',
    iconClass: 'text-red-500',
    backgroundInverseClass: 'bg-red-50',
    icon: 'close-circle',
  },
  6: {
    label: 'Cancelada',
    backgroundClass: 'bg-gray-200',
    backgroundColor: '#e2e8f0',
    borderClass: 'border-gray-400',
    borderColor: '#94a3b8',
    iconClass: 'text-gray-500',
    backgroundInverseClass: 'bg-gray-100',
    icon: 'ban',
  },
};

@Component({
  selector: 'app-tag-estado-revision',
  template: `
    <div *ngIf="!indicatorMode" class="inline-flex space-x-1 items-center px-2 py-0.5 rounded-full {{ wrapperClass }} {{estadoActivo?.class}} border {{estadoActivo?.borderClass}} " [title]="estadoActivo?.label">
      <span class="text-sm font-medium leading-4 {{estadoActivo?.textClass}}" >{{ status ? status?.nombre : estadoActivo?.label }}</span>
      <ng-content></ng-content>
    </div>
    <div *ngIf="indicatorMode" class="w-5 w-5 rounded  rounded-full {{ estadoActivo?.class }} {{ wrapperClass }}"></div>
  `,
})
export class TagEstadoRevisionComponent implements OnInit, OnChanges {

  // TODO: Llamar a base de datos para obtener los estados -> Traducir
  @Input() onlyActive = false;
  @Input() indicatorMode = false;
  @Input() status: EstadoRevision;
  @Input() tagClass: string;
  @Input() wrapperClass: string;
  @Input() statusId: number;
  estadoActivo;

  estados = [
    {id: 1, label: 'Pendiente', class: 'bg-orange-50', borderClass: 'border-orange-300', textClass: 'text-orange-800'},
    {id: 2, label: 'En proceso', class: 'bg-amber-50', borderClass: 'border-amber-400', textClass: 'text-amber-800'},
    {id: 3, label: 'Finalizada', class: 'bg-teal-50', borderClass: 'border-teal-400', textClass: 'text-teal-800'},
    {id: 4, label: 'Validada', class: 'bg-green-50', borderClass: 'border-green-400', textClass: 'text-green-800'},
    {id: 5, label: 'Rechazada', class: 'bg-red-50', borderClass: 'border-red-400', textClass: 'text-red-800'},
    {id: 6, label: 'Cancelada', class: 'bg-gray-100', borderClass: 'border-gray-400', textClass: 'text-gray-800'},
  ];

  constructor() {}

  ngOnInit() {
    this.estadoActivo = this.estados.find((e) => e.id === (this.status ? this.status.id : this.statusId));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.status || changes.statusId) {
this.estadoActivo = this.estados.find((e) => e.id === (this.status ? this.status.id : this.statusId));
}
  }

}
