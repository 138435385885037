<nav class="az-breadcrumb flex space-x-3 items-center py-2">
  <ul class="flex items-center list-none">
    <li *ngFor="let breadcrumb of breadcrumbs; let i = index" class="az-breadcrum-item flex items-center">
      <a class="truncate max-w-sm text-base rounded-lg px-2 py-1.5 color-text-primary hover:cursor-pointer hover:bg-gray-200  {{breadcrumb.last ? 'font-semibold' : ''}}" *ngIf="breadcrumb.clickable && !breadcrumb.last" [routerLink]="['../' + breadcrumb.url]" routerLinkActive="az-breadcrum-item-selected">
        {{ breadcrumb.isResolved ? breadcrumb.label : (breadcrumb.label | transloco: breadcrumb.params) }}
      </a>
      <a class="truncate max-w-sm text-base rounded-lg px-2 py-1.5 color-text-primary {{breadcrumb.last ? 'font-semibold' : ''}}" *ngIf="!breadcrumb.clickable || breadcrumb.last">
        {{ breadcrumb.isResolved ? breadcrumb.label : (breadcrumb.label | transloco: breadcrumb.params) }}
      </a>
      <span *ngIf="!breadcrumb.last">/</span>
    </li>
  </ul>
</nav>
