import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {AbstractAPIService} from '@shared/interfaces/service-classes';
import {Observable} from "rxjs";
import {Subestado} from "../models/subestado.model";
import {AsistenciaTecnica} from "../../modules/backoffice/models/asistencia-tecnica.model";

@Injectable()
export class AsistenciaTecnicaService extends AbstractAPIService {

  buildUrlBase(): string {
    return environment.api.asistenciaTecnica.usuario;
  }

  getAll(): Observable<AsistenciaTecnica[]> {
    return this.getHttp().get<AsistenciaTecnica[]>(this.buildUrlBase() + '/all');
  }

}
