import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {PaginatedResponse, SearchRequest} from '@azigrene/searchrequest';
import {EstadoGeneralModel} from '../pages/general-status/estado';
import {Store} from "@ngxs/store";
import moment from "moment";
import {take} from "rxjs/operators";
import {AuthService} from "../../../service/auth.service";
import {ActualizarTareasHoy} from "@store/home/home.actions";
import {EstadoRevisionEnum} from "@shared/enum/estado-revision.enum";

@Injectable()
export class MyTasksService {
  base = 'my-tasks';

  constructor(private http: HttpClient, private store: Store, private authService: AuthService) {
  }

  all(searchRequest = new SearchRequest()) {
    return this.http.post<PaginatedResponse<EstadoGeneralModel>>(`${environment.urls.api}/${this.base}/all`, searchRequest);
  }

  reloadStats() {
    const searchRequest: SearchRequest = new SearchRequest();
    searchRequest.setPageSize(250);
    searchRequest.addFilter('responsable_in', this.authService.getUser().email, false);
    searchRequest.addFilter('estado_in', [EstadoRevisionEnum.PENDIENTE.codigo, EstadoRevisionEnum.EN_PROCESO.codigo], false);
    searchRequest.removeFilter('fecha_teorica_ejecucion_between', false);
    searchRequest.addFilter('fecha_teorica_ejecucion_between', [moment('1900-01-01'), moment()], false);
    searchRequest.addSort('fecha_teorica_ejecucion', 'DESC', false);
    this.all(searchRequest).pipe(take(1)).subscribe(tasks => {
      this.store.dispatch(new ActualizarTareasHoy(tasks.data || []));
    });
  }
}
