import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {TaggerModel} from '@azigrene/components';
import {Observable} from 'rxjs';
import {EtiquetaService} from '../../../../modules/frontoffice/services/etiqueta.service';
import {map, take} from 'rxjs/operators';
import {TareaActions} from '../../../../modules/frontoffice/actions/TareaActions';
import {TareaService} from '../../../../modules/frontoffice/services/tarea.service';
import {Etiqueta, EtiquetasArray} from "@shared/models/etiqueta.model";

@Component({
  selector: 'app-tag-selector',
  template: `
    <az-tag-manager class="flex-1"
                    [tagUpdateObservable]="tagUpdObs"
                    [tagDeleteObservable]="tagDeleteObs"
                    [tagCreateObservable]="tagCreateObs"
                    [tagListObservable]="tagObs"
                    (ngModelChange)="updateTags($event)"
                    [editable]="true" [ngModel]="_tags">
    </az-tag-manager>
  `,
  styles: []
})
export class TagSelectorComponent implements OnChanges {
  @Input() contratoId: number;
  @Input() tareaId: number;
  @Output() onUpdateSuccess = new EventEmitter();

  @Input() tags: EtiquetasArray;
  _tags: TaggerModel[];

  tagObs: () => Observable<TaggerModel[]>;
  tagUpdObs: (tag: TaggerModel) => Observable<TaggerModel>;
  tagCreateObs: (tag: TaggerModel) => Observable<TaggerModel>;
  tagDeleteObs: (tagId: number) => Observable<boolean>;

  constructor(private etiquetasService: EtiquetaService, private tareaService: TareaService) {
    this.tagObs = () => this.etiquetasService.getAll(this.contratoId).pipe(map((e: Etiqueta[]) => {
      return e.map(v => ({
        id: v.id,
        label: v.nombre,
        color: v.color
      }));
    }));
    this.tagCreateObs = (tag: TaggerModel) => this.etiquetasService.create( {
      id: null,
      contratoId: this.contratoId,
      color: tag.color,
      nombre: tag.label
    }, this.contratoId).pipe(map((v: Etiqueta) => {
      return ({
        id: v.id,
        label: v.nombre,
        color: v.color
      });
    }));
    this.tagUpdObs = (tag: TaggerModel) => this.etiquetasService.update({
      id: tag.id,
      contratoId: this.contratoId,
      color: tag.color,
      nombre: tag.label
    }, this.contratoId).pipe(map((v: Etiqueta) => {
      return ({
        id: v.id,
        label: v.nombre,
        color: v.color
      });
    }));
    this.tagDeleteObs = (tagId) => this.etiquetasService.delete(tagId, this.contratoId).pipe(map((isDeleted: boolean) => isDeleted));
  }

  updateTags(event: TaggerModel[]): void {
    const etiquetas: Etiqueta[] = event.map(e => ({nombre: e.label, color: e.color, id: e.id}));
    this.tareaService.accionLista([this.tareaId], TareaActions.SET_TAGS, {tags: etiquetas}, this.contratoId).pipe(take(1)).subscribe(n => {
      if (n) {
        this.onUpdateSuccess.emit();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tags) {
      this._tags = changes.tags.currentValue ? changes.tags.currentValue.jsonArray.map(v => ({
        id: v.id,
        label: v.nombre,
        color: v.color
      })) : [];
    }
  }

}
