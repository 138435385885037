
<div class="flex flex-col h-full w-full">
  <app-stepper
    headerTitle="{{ 'dialogo-importar.titulo' | transloco }}"
    headerSubtitle="{{ 'dialogo-importar.subtitulo' | transloco }}"
    #stepper
    activeStep="tipoFichero"
    [steps]="steps"
    [contentTemplate]="t"
    (nextStep)="onNextStep($event)"
    (lastStep)="ref.close()"
    (previousStep)="onPrevious($event)"
  >
    <az-icon icon="x" iconClass="text-primary-lighter font-medium" wrapperClass="mb-5 cursor-pointer" size="lg" azStepperHeaderRight azTooltip="{{ 'misc.cerrar' | transloco }}" tooltipPosition="bottom" (click)="ref.close()"></az-icon>
  </app-stepper>
  <ng-template #t let-activeStep="activeStep">
    <az-empty-state *ngIf="loading" icon="arrow-up" iconClass="animate-pulse animate-bounce" text="{{ 'dialogo-importar.importando-text' | transloco }}" title="{{ 'dialogo-importar.importando-title' | transloco }}"></az-empty-state>
    <ng-container *ngIf="!loading" [ngSwitch]="activeStep">
      <ng-container *ngSwitchCase="'seleccion'">
        <form [formGroup]="formTipoImportacion">
          <ul class="w-full data-list">
            <li class="data-list-item p-4 cursor-pointer hover:bg-primary-lighter" (click)="formTipoImportacion.controls.tipoImportacion.setValue('file')">
              <input value="file" type="radio" class="input-radio" formControlName="tipoImportacion" />
              <div class="flex flex-col ml-4 items-start">
                <span class="text-lg">{{ 'dialogo-importar.importar-fichero' | transloco }}</span>
                <small>{{ 'dialogo-importar.seleccione-fichero' | transloco }}</small>
              </div>
            </li>
          </ul>
        </form>
      </ng-container>
      <ng-container *ngSwitchCase="'tipoFichero'">
        <div class="flex flex-wrap m-auto my-2 ng-star-inserted p-8 rounded w-full" *ngIf="!loading && !resultado">
          <app-form-file class="w-full" [returnAsFile]="true" [requiredType]="['spreadsheet', 'application/vnd.ms-excel', 'application\\S+.xlsx', 'file/']" [(ngModel)]="file" (ngModelChange)="setFile($event)"></app-form-file>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'resultados'">
        <div *ngIf="resultado" class="flex h-full flex-col pt-4">
          <div class="flex flex-wrap mx-8 border rounded p-3">
            <div class="w-1/4 text-center px-1">
              <span class="font-semibold">{{ 'dialogo-importar.procesados' | transloco }}</span>
              <h2 id="dialogo-importar.procesados" class="text-neutral-400">{{ resultado.procesados }}</h2>
            </div>
            <div class="w-1/4 text-center px-1">
              <span class="font-semibold">{{ 'dialogo-importar.insertados' | transloco }}</span>
              <h2 id="dialogo-importar.insertados" class="text-success-400">{{ resultado.insertados }}</h2>
            </div>
            <div class="w-1/4 text-center px-1">
              <span class="font-semibold">{{ 'dialogo-importar.actualizados' | transloco }}</span>
              <h2 id="dialogo-importar.actualizados" class="text-orange-400">{{ resultado.actualizados }}</h2>
            </div>
            <div class="w-1/4 text-center px-1">
              <span class="font-semibold">{{ 'dialogo-importar.erroneos' | transloco }}</span>
              <h2 id="dialogo-importar.erroneos" class="text-danger-400">{{ resultado.erroneos }}</h2>
            </div>
          </div>
          <div class="flex flex-wrap overflow-y-auto flex-1 h-full">
            <div *ngIf="!showErroresAvisos" class="w-full md:w-1/1 flex flex-col h-full">
              <div class="p-4 border-b">
                <h3>{{ 'dialogo-importar.errores' | transloco }}</h3>
              </div>
              <ul class="px-4 flex-1 overflow-y-auto h-full border-r">
                <li class="px-4 py-1 border-b">
                  <div class="flex items-center">
                    <div class="flex bg-gray w-full">
                      <div class="text-sm text-red-600 flex flex-col ml-2">
                        {{ errorFileProcess }}
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <div *ngIf="showErroresAvisos" class="w-full md:w-1/2 flex flex-col h-full">
              <div class="p-4 border-b">
                <h3>{{ 'dialogo-importar.errores' | transloco }}</h3>
              </div>
              <ul class="px-4 flex-1 overflow-y-auto h-full border-r">
                <li *ngFor="let d of errores" class="px-4 py-1 border-b">
                  <div class="flex">
                    <span class="flex-none w-16 font-semibold color-text-primary">[{{ d.fila }}]</span>

                    <ul class="list-disc bg-gray col-span-2">
                      <li class="text-sm text-red-600" *ngFor="let e of d.errores">[{{ e.campo }}] {{ e.mensaje }}</li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>

            <div *ngIf="showErroresAvisos" class="w-full md:w-1/2 flex flex-col h-full">
              <div class="p-4 border-b">
                <h3>{{ 'dialogo-importar.avisos' | transloco }}</h3>
              </div>
              <ul class="px-4 flex-1 overflow-y-auto">
                <li *ngFor="let d of avisos" class="px-4 py-1 border-b">
                  <div class="flex">

                      <span class="flex-none w-16 font-semibold color-text-primary">[{{ d.fila }}]</span>

                    <ul class="list-disc bg-gray col-span-2">
                      <li class="text-sm text-orange-600" *ngFor="let e of d.avisos">[{{ e.campo }}] {{ e.mensaje }}</li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="hasErrors" class="flex w-full justify-center p-4">
            <button azButton level="primary" id="dialogo-importar.descargar-errores-avisos" (click)="downloadDocumento()">
              <span>{{ 'misc.descargar-errores-avisos' | transloco }}</span>
            </button>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-template>
</div>
