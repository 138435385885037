<div class="contribution-chart">
  <table class="border-separate" *ngIf="mapOfWeekdays">
    <thead>
    <tr>
      <td class="w-12"></td>
      <td *ngFor="let entry of months" [style.width.px]="11" [style.height.px]="11"
          class="overflow-x-auto"
          [colSpan]="entry.span">
        {{ entry.firstDate | localizedDatePart:'DDMMYYYY':'MMM'}}
      </td>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let weekday of weekdays">
      <td class="w-10 text-xs truncate" [style.height.px]="11"
          [style.line-height.px]="0">{{ translateWeekday(weekday) }}</td>
      <td *ngFor="let week of mapOfWeekdays[weekday]" [title]="week.date | localizedDate"
          class="contribution-chart-day {{week.inRange && !week.data ? 'contribution-chart-empty-day ' : ''}} {{week.data && dayClicked ? 'hover:cursor-pointer' :  ''}} "
          [style.border-color]="week.data?.borderColor" [style.border-width.px]="week.data ? 1 : 0"
          [style.background-color]="week.data?.backgroundColor" [style.width.px]="11" [style.height.px]="11"
          (click)="dayClicked.emit(week.data?.item)"
      ></td>
    </tr>
    </tbody>
  </table>
</div>
