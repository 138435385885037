import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: '[azTableLinkCell]',
  template: `
    <span class='flex-1 truncate group-hover:pr-16'>{{text}}</span>

    <span class='group-hover:visible absolute invisible right-1.5 bg-gray-100 inset-y-auto text-gray-500'>
    <div *ngIf='icon' [innerHTML]='sanitizer.bypassSecurityTrustHtml(icon)'></div>
    <svg *ngIf='!icon'
         xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor'>
      <path d='M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z' />
      <path d='M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z' />
    </svg>
    </span>
  `,
  styles: []
})
export class DataTableLinkCellComponent implements OnInit {
  @HostBinding('class') class = 'group hover:bg-gray-100 cursor-pointer relative w-full h-full items-center px-px flex';
  @Input() text = '';
  @Input() icon: string;
  @Output() onClick = new EventEmitter();

  constructor(public sanitizer: DomSanitizer) {
  }

  ngOnInit() {
  }

}
