import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { PaginatedResponse, SearchRequest } from '@azigrene/searchrequest';
import { EntregableTarea } from '@shared/models/entregable.model';

@Injectable()
export class EntregableTareaService {
  constructor(private http: HttpClient) {
  }

  all(contratoId: number, tareaId: number, searchRequest: SearchRequest) {
    return this.http.post<PaginatedResponse<EntregableTarea>>(
      `${environment.urls.api}/contracts/${contratoId}/tasks/${tareaId}/deliverables`,
      searchRequest
    );
  }

  one(id: number, tareaId: number, contratoId: number) {
    return this.http.get<EntregableTarea>(`${environment.urls.api}/contracts/${contratoId}/tasks/${tareaId}/deliverables/${id}`);
  }

  create(contratoId: number, tareaId: number, data: EntregableTarea) {
    return this.http.post<EntregableTarea>(`${environment.urls.api}/contracts/${contratoId}/tasks/${tareaId}/deliverables/create`, data);
  }

  update(id: number, contratoId: number, tareaId: number, data: EntregableTarea) {
    return this.http.post<EntregableTarea>(
      `${environment.urls.api}/contracts/${contratoId}/tasks/${tareaId}/deliverables/${id}/update`,
      data
    );
  }

  delete(id: number, contratoId: number, tareaId: number) {
    return this.http.delete<EntregableTarea>(`${environment.urls.api}/contracts/${contratoId}/tasks/${tareaId}/deliverables/${id}/delete`);
  }

  estadoUltimaRevision(contratoId: number, tareaId: number) {
    return this.http.get<EntregableTarea[]>(`${environment.urls.api}/contracts/${contratoId}/tasks/${tareaId}/deliverables/status`);
  }
}
