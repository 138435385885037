import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {TRANSLOCO_CONFIG, translocoConfig, TranslocoModule} from '@ngneat/transloco';
import {MomentModule} from 'ngx-moment';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {es_ES, NZ_I18N} from 'ng-zorro-antd/i18n';
import {ContratoService} from '../modules/frontoffice/services/contrato.service';
import {ContratanteService} from './services/contratante.service';
import {TareaService} from '../modules/frontoffice/services/tarea.service';
import {EstadoRevisionComponent} from './components/estado-revision/estado-revision.component';
import {RevisionService} from '../modules/frontoffice/services/revision.service';
import {HistoricoRevisionService} from '../modules/frontoffice/services/historico-revision.service';
import {EnumService} from '../modules/frontoffice/services/enum.service';
import {GeneralService} from '../modules/frontoffice/services/general.service';
import {SubirDocumentoComponent} from './components/subir-documento/subir-documento.dialog';
import {DocumentoService} from '../modules/frontoffice/services/documento.service';
import {RouteModalComponent} from './components/modal/modal.component';
import {AdjudicatarioService} from '@shared/services/adjudicatario.service';
import {BackofficeContratoService} from '../modules/backoffice/services/contrato.service';
import {BackofficeUsuarioService} from '../modules/backoffice/services/usuario.service';
import {BackofficeMiembroService} from '../modules/backoffice/services/miembro.service';
import {EtiquetaService} from '../modules/frontoffice/services/etiqueta.service';
import {EntregableContratoService} from '../modules/frontoffice/services/entregable.service';
import {ElementoActuacionService} from '../modules/frontoffice/services/elemento-actuacion.service';
import {TipoContratoService} from "@shared/services/tipo-contrato.service";
import {EntregableTareaService} from '../modules/frontoffice/services/entregable-tarea.service';
import {MiembroService} from '../modules/frontoffice/services/miembro.service';
import {AzComponentsModule, AzModalService} from '@azigrene/components';
import {TagEstadoRevisionComponent} from '@shared/components/tag-estado-revision/tag-estado-revision.component';
import {FechaEntregaComponent} from '@shared/components/fecha-entrega/FechaEntrega.component';
import {TagPrioridadTareaComponent} from './components/tag-prioridad-tarea/tag-prioridad-tarea.component';
import {PrioridadSelectorComponent} from './components/selectors/prioridad-selector/prioridad-selector.component';
import {EstadoSelectorComponent} from '@shared/components/selectors/estado-selector/estado-selector.component';
import {MyTasksService} from '../modules/frontoffice/services/my-tasks.service';
import {AssigneesSelectorComponent} from './components/selectors/assignees-selector/assignees-selector.component';
import {AssigneeSingleComponent} from '@shared/components/selectors/assignee-selector/assignee.component';
import {AssigneeSelectorComponent} from '@shared/components/selectors/assignee-selector/assignee-selector.component';
import {TagSelectorComponent} from './components/selectors/tag-selector/tag-selector.component';
import {PrioritySelectorComponent} from './components/selectors/priority-selector/priority-selector.component';
import {PeriodicitySelectorComponent} from './components/selectors/periodicity-selector/periodicity-selector.component';
import {PriorityDropdownComponent} from '@shared/components/selectors/priority-selector/priority-dropdown.component';
import {TareaSubirDocumentoComponent} from '../modules/frontoffice/pages/tarea/dialogs/tarea-subir-documento/tarea-subir-documento.dialog';
import {RevisionSubirDocumentoComponent} from '../modules/frontoffice/pages/revision/dialogs/revision-subir-documento/revision-subir-documento.dialog';
import {EventsListComponent} from './components/events-list/events-list.component';
import {ResponsableSelectorDropdownComponent} from './components/selectors/assignee-selector/responsable-selector-dropdown.component';
import {AssigneesSelectorDropdownComponent} from '@shared/components/selectors/assignees-selector/assignees-selector-dropdown.component';
import {PeriodicitySelectorDropdownComponent} from '@shared/components/selectors/periodicity-selector/periodicity-dropdown.component';
import {EstadoSelectorDropdownComponent} from './components/selectors/estado-selector/estado-selector-dropdown.component';
import {DataTableLinkCellComponent} from './components/data-table-link-cell/data-table-link-cell.component';
import {environment} from '../../environments/environment';
import {AzDataManagerModule} from '@azigrene/data-manager';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {DropdownModule} from 'primeng/dropdown';
import {DialogModule} from 'primeng/dialog';
import {MenuModule} from 'primeng/menu';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NzTimelineModule} from 'ng-zorro-antd/timeline';
import {AzPipesModule, AzPipesService} from '@azigrene/pipes';
import {AzDownloaderModule} from '@azigrene/downloader';
import {NgxsModule} from '@ngxs/store';
import {TokenInterceptor} from '@store/interceptors/token.interceptor';
import {DataImportExportComponent} from '@shared/components/data-import-export/data-import-export.component';
import {NzDatePickerModule} from 'ng-zorro-antd/date-picker';
import {BreadcrumbComponent} from '@shared/components/breadcrumb/breadcrumb.component';
import {ContributionChartComponent} from './components/contribution-chart/contribution-chart.component';
import {DialogoImportacionComponent} from './components/dialogo-importacion/dialogo-importacion.component';
import {StepperComponent} from './components/stepper/stepper.component';
import {FormFileComponent} from '@shared/form-file/form-file.component';
import {HomeState} from '@store/home/home.store';
import {InputErrorPipe} from '@shared/pipes/valid-input.pipe';
import {FormSectionComponent, FormSpaceComponent} from '@shared/components/form-section/form-section.component';
import {AzValidControlDirective, AzValidDirective} from '@shared/directives/az-valid.directive';
import {SubestadoService} from "@shared/services/subestado.service";
import {TareaDetalleComponent} from "@shared/components/tarea-detalle/tarea-detalle.component";
import {DocumentoTareaService} from '../modules/frontoffice/services/documento-tarea.service';

import {AsistenciaTecnicaService} from '@shared/services/asistencia-tecnica.service';

const components = [
  EstadoRevisionComponent,
  SubirDocumentoComponent,
  RouteModalComponent,
  TagEstadoRevisionComponent,
  TagPrioridadTareaComponent,
  EstadoSelectorComponent,
  FechaEntregaComponent,
  PrioridadSelectorComponent,
  AssigneeSelectorComponent,
  AssigneeSingleComponent,
  PrioritySelectorComponent,
  PriorityDropdownComponent,
  AssigneesSelectorDropdownComponent,
  ResponsableSelectorDropdownComponent,
  TareaSubirDocumentoComponent,
  RevisionSubirDocumentoComponent,
  PeriodicitySelectorDropdownComponent,
  EstadoSelectorDropdownComponent,
  DataTableLinkCellComponent,
  AssigneesSelectorComponent,
  TagSelectorComponent,
  PeriodicitySelectorComponent,
  EventsListComponent,
  DataImportExportComponent,
  BreadcrumbComponent,
  DialogoImportacionComponent,
  StepperComponent,
  FormFileComponent,
  FormSectionComponent,
  FormSpaceComponent,
  ContributionChartComponent,
  TareaDetalleComponent
];

const imports = [
  HttpClientModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  TranslocoModule,
  MomentModule,
  NzTimelineModule,
  NzDatePickerModule,
  NgxsModule,
  // AngularSvgIconModule,
  AutoCompleteModule,
  DropdownModule,
  MenuModule,
  DialogModule,

  /* AZ modules */
  AzPipesModule,
  AzDownloaderModule,
  AzDataManagerModule,
  AzComponentsModule
];

const services = [
  ContratoService,
  ContratanteService,
  TareaService,
  DocumentoTareaService,
  RevisionService,
  HistoricoRevisionService,
  GeneralService,
  DocumentoService,
  EnumService,
  AdjudicatarioService,
  BackofficeContratoService,
  AsistenciaTecnicaService,
  BackofficeUsuarioService,
  BackofficeMiembroService,
  EntregableContratoService,
  EntregableTareaService,
  EtiquetaService,
  MiembroService,
  ElementoActuacionService,
  MyTasksService,
  AzPipesService,
  SubestadoService,
  TipoContratoService,
  DatePipe
];

const pipes = [InputErrorPipe];
const directives = [AzValidControlDirective, AzValidDirective];

@NgModule({
  declarations: [...components, ...pipes, ...directives],
  imports: [CommonModule, imports, NgxsModule.forFeature([HomeState])],
  exports: [...imports, ...components, ...pipes, ...directives],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    {provide: NZ_I18N, useValue: es_ES},
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: ['en', 'es'],
        defaultLang: 'es',
        reRenderOnLangChange: true,
        prodMode: environment.production,
        fallbackLang: 'es',
      }),
    },
    AzModalService,
    ...services,
  ],
})
export class SharedModule {}
