<div class="modal-content !p-0" [tabIndex]="-1" #revisionModal
     (keydown.escape)="this.ref.close(); revisionModal.blur();">
  <ng-container *ngIf="revisionRequest.withData">
    <!--    <div class="w-full">-->
    <!--      <az-alert *ngIf="!config.showHeader" mode="info" [text]="'frontoffice.dialogs.revision.viendo-ejecucion' | transloco">-->
    <!--        <button azButton (click)="openTareaDialog()">-->
    <!--          {{ 'frontoffice.dialogs.revision.ver-tarea' | transloco }}-->
    <!--        </button>-->
    <!--      </az-alert>-->
    <!--    </div>-->
    <div class="rounded-lg color-bg-primary shadow-lg p-4 z-50">
      <div class="w-full relative flex items-start mb-2 justify-between">
        <div class="flex flex-col">
          <span class="text-xs color-text-secondary">{{ revision.contrato.nombre }}</span>
          <p class="text-base color-text-primary">{{ revision.tarea.nombre }}</p>
        </div>
        <div class="flex space-x-1 items-center">
          <az-icon icon="calendar-days" size="custom" iconClass="w-4 h-4 text-red-400"></az-icon>
          <span class="font-medium">{{ revision.fechaTeoricaEjecucion | localizedDate }}</span>
        </div>
      </div>
      <div class="mb-8">
        <p
          class="text-base color-text-secondary whitespace-pre-wrap">{{ revision.tarea.descripcion ? revision.tarea.descripcion : 'Sin descripción' }}</p>
      </div>
      <div class="mb-2 flex items-center space-x-2">
        <az-assignees-circle *ngIf="revision.miembrosArray || revision.responsable"
                             [usernamesToHighlight]="revision.responsable ? [revision.responsable.email] : []"
                             [assigneesArray]="revision.miembrosArray?.jsonArray"
                             usernameKey="email"
                             [actionable]="false"
                             [limit]="false"
                             [small]="true"
                             [overlaps]="false"
        >
        </az-assignees-circle>
        <az-tagger-item *ngFor="let tag of revision.etiquetasArray?.jsonArray" [text]="tag.nombre"
                        wrapperClass="rounded-full px-2.5 py-0.5 !bg-emerald-700/20 !text-emerald-800/80"
                        itemClass="font-medium"
                        [color]="tag.color"></az-tagger-item>
      </div>
      <div class="mb-2 flex space-x-2 items-center">
        <div class="w-4 h-4 bg-blue-100 flex items-center justify-center rounded-full">
          <az-icon iconClass="text-blue-600" icon="arrow-uturn-right" size="sm"></az-icon>
        </div>
        <span>{{periodicidadVisual}}</span>
        <div class="flex-1"></div>
        <div class="flex space-x-2 items-center">
          <app-estado-selector [statusId]="revision.estadoId"
                               (onChange)="updateStatus($event, revision)"></app-estado-selector>
          <az-select
            *ngIf="subestados.length"
            labelProp="label"
            valueProp="id"
            [options]="subestados"
            [clearable]="true"
            [(ngModel)]="revision.subestadoId"
            (ngModelChange)="updateStatus(revision.estadoId, revision, $event)"
            placeholder="{{ 'misc.sin-asignar' | transloco }}"
          >
          </az-select>
        </div>
      </div>
    </div>
    <div class="px-4 z-40">
      <div class="flex w-full space-x-4 rounded-b-lg bg-gray-50 border-t shadow-lg">
        <div class="flex-1 overflow-auto">
          <div class="flex flex-1 flex-wrap overflow-y-auto h-full pb-4">
            <div class="w-full lg:flex-1 overflow-y-auto" *ngIf="revision">
              <div class="flex flex-col w-full p-4">
                <div class="flex items-center space-x-2 mb-1">
                  <p class="font-semibold">{{ 'frontoffice.dialogs.revision.documents' | transloco }}</p>
                  <div class="flex-1"></div>
                  <button azButton (click)="openDialogoDocumento()" scheme="link">
                    {{ 'actions.upload' | transloco }}
                  </button>
                </div>
                <ul *ngIf="documentosRequest.withData" class="!list-disc">
                  <li *ngFor="let documento of documentosRequest.data">
                    <div class="flex space-x-2 items-center">
                      <button azButton scheme="link"
                              (click)="downloadDoc(documento.documento)">{{ documento.documento.nombre }}</button>
                      <div class="flex-1"></div>
                      <a class="underline color-text-secondary hover:text-primary"
                         (click)="deleteDoc(documento.documento)">
                        {{ 'actions.delete' | transloco }}
                      </a>
                    </div>
                  </li>
                </ul>
                <div *ngIf="documentosRequest.empty" class="w-full">
                  <span
                    class="color-text-tertiary">{{ 'frontoffice.dialogs.revision.documents-empty' | transloco }}</span>
                </div>
              </div>
              <!--        Actividad -->
              <div class="w-full relative">
                <div *ngIf="historicosRevisionRequest.withData">
                  <div *ngFor="let history of historicosRevisionRequest.data.data" class="relative">
                    <div class="flex items-start p-4 border-t">
                      <ng-container [ngSwitch]="history.accionId">
                        <!--cambio estado-->
                        <ng-container *ngSwitchCase="1">
                          <div class="flex flex-col w-full">
                            <div class="flex items-start space-x-2">
                              <az-assignees-circle-item
                                tyleClass="w-8 h-8 leading-8 bg-blue-500 color-text-inverse text-base flex-shrink-0"
                                [overlaps]="false"
                                [text]="history.usuario?.email">
                              </az-assignees-circle-item>
                              <div class="flex-1 space-y-1">
                                <p class="font-medium">{{history.usuario?.email}}</p>
                                <span class="text-xs color-text-tertiary"
                                      azTooltip="{{ history.createdAt | localizedDatetime }}">{{ history.createdAt | amTimeAgo }}</span>
                              </div>
                              <div class="self-center mr-2">
                                <span>{{'home.revision.cambioEstado' | transloco}}</span>
                                <app-tag-estado-revision tagClass="leading-4"
                                                         [statusId]="history.datos.estadoId"></app-tag-estado-revision>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                        <!--comentario-->
                        <ng-container *ngSwitchCase="2">
                          <div class="flex flex-col w-full">
                            <div class="flex items-start space-x-2">
                              <az-assignees-circle-item
                                tyleClass="w-8 h-8 leading-8 bg-blue-500 color-text-inverse text-base flex-shrink-0"
                                [overlaps]="false"
                                [text]="history.usuario?.email">
                              </az-assignees-circle-item>
                              <div class="flex-1 space-y-1">
                                <p class="font-medium">{{history.usuario?.email}}</p>
                                <span class="text-xs color-text-tertiary"
                                      azTooltip="{{ history.createdAt | localizedDatetime }}">{{ history.createdAt | amTimeAgo }}</span>
                              </div>
                            </div>
                            <p
                              class="flex-1 color-text-primary break-words whitespace-pre-wrap my-4 ml-10">{{ history.datos.comentario }}</p>
                            <div class="flex space-x-2 ml-10">
<!--                              <button azButton size="sm"-->
<!--                                      (click)="editingComment = history.id">{{ 'actions.edit' | transloco }}</button>-->
                              <button azButton size="sm"
                                      (click)="delete(history)">{{ 'actions.delete' | transloco }}</button>
                            </div>
                          </div>
                        </ng-container>
                        <!--documento -->
                        <ng-container *ngSwitchCase="3">
                          <div class="flex flex-col w-full">
                            <div class="flex items-start space-x-2">
                              <az-assignees-circle-item
                                tyleClass="w-8 h-8 leading-8 bg-blue-500 color-text-inverse text-base flex-shrink-0"
                                [overlaps]="false"
                                [text]="history.usuario?.email">
                              </az-assignees-circle-item>
                              <div class="flex-1 space-y-1">
                                <p class="font-medium">{{history.usuario?.email}}</p>
                                <span class="text-xs color-text-tertiary"
                                      azTooltip="{{ history.createdAt | localizedDatetime }}">{{ history.createdAt | amTimeAgo }}</span>
                              </div>
                              <div class="self-center mr-2">
                                <span>{{'home.revision.subidaDocumento' | transloco}}</span>
                                <a class="underline color-text-secondary" (click)="downloadDoc(history.documento)">
                                  {{ history.documento?.nombre }}
                                </a>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div *ngIf="!historicosRevisionRequest.loading" [class.mt-4]="historicosRevisionRequest.empty" class="p-3">
                <textarea placeholder="Añade un comentario..."
                          class="border color-bg-secondary color-border-primary min-h-24 p-2 rounded w-full"
                          [(ngModel)]="commentText"></textarea>
                  <div class="w-full flex justify-end py-1">
                    <button azButton level="primary" [disabled]="!commentText" #sendbutton
                            (click)="createComment(commentText); commentText = ''">
                      {{ 'frontoffice.dialogs.revision.new-comment' | transloco }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!--      <div class="overflow-y-auto flex w-full space-y-0 md:block md:w-52 md:space-y-4"></div>-->
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="revisionRequest.loading">
    <az-empty-state presetStyle="loading"></az-empty-state>
  </ng-container>
</div>
