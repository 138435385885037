import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Miembro } from '@shared/models/auth.model';

@Injectable()
export class MiembroService {
  constructor(private http: HttpClient) {
  }

  own(idContrato: number) {
    return this.http.get<Miembro>(`${environment.urls.api}/contracts/${idContrato}/members/one`);
  }

  all(idContrato: number) {
    return this.http.get<Miembro[]>(`${environment.urls.api}/contracts/${idContrato}/members/all`);
  }

  allFilter(idContrato: number) {
    return this.http.get<Miembro[]>(`${environment.urls.api}/member/list/${idContrato}`);
  }
}
