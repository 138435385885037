<form [formGroup]="form" class="p-4">
  <div class="az-form-group">
    <label>Fichero</label>
    <input (change)="onFileChange($event)" type="file" />
  </div>
  <div class="az-form-group">
    <label>Nombre</label>
    <az-input-text formControlName="nombre" type="text"></az-input-text>
  </div>
  <div class="az-form-group">
    <label>Observaciones</label>
    <textarea class="data" formControlName="observaciones"></textarea>
  </div>

  <div class="mt-4 flex justify-end">
    <button azButton level="primary" [disabled]="!form.valid" (click)="submit()">
      {{ 'actions.create' | transloco }}
    </button>
  </div>
</form>
