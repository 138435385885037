<div class="modal-content">
  <form [formGroup]="form">
    <div class="az-form-group">
      <label> {{ 'home.tarea.documento.fichero' | transloco }}</label>
      <input class="az-input" (change)="onFileChange($event)" type="file" />
    </div>
    <div class="az-form-group">
      <label> {{ 'common.nombre' | transloco }}</label>
      <az-input-text formControlName="nombre" type="text"></az-input-text>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button azButton level="primary" [disabled]="!form.valid" (click)="submit()">{{ 'actions.create' | transloco }}</button>
</div>
