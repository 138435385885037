import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DocumentoRevision, HistoricoRevisionTareaDatos, Revision } from '@shared/models/home.model';
import { environment } from 'src/environments/environment';
import { UploadDocumento } from '@shared/models/generic.model';
import { PaginatedResponse, SearchRequest } from '@azigrene/searchrequest';

@Injectable()
export class RevisionService {
  constructor(private http: HttpClient) {
  }

  allByTarea(contratoId, tareaId, searchRequest) {
    return this.http.post<PaginatedResponse<Revision>>(`${environment.urls.api}/contracts/${contratoId}/tasks/${tareaId}/revisions`, searchRequest);
  }

  allByContrato(contratoId, searchRequest) {
    return this.http.post<PaginatedResponse<Revision>>(`${environment.urls.api}/contracts/${contratoId}/revisions`, searchRequest);
  }

  getOne(contratoId, tareaId, revisionId) {
    return this.http.get<Revision>(`${environment.urls.api}/contracts/${contratoId}/tasks/${tareaId}/revisions/${revisionId}`);
  }

  updateEstado(contratoId, tareaId, revisionId, datos: HistoricoRevisionTareaDatos) {
    return this.http.post<HistoricoRevisionTareaDatos>(
      `${environment.urls.api}/contracts/${contratoId}/tasks/${tareaId}/revisions/${revisionId}/updateStatus`,
      datos
    );
  }

  updateFechaTeoricaEjecucion(contratoId, tareaId, revisionId, datos: HistoricoRevisionTareaDatos) {
    return this.http.post<HistoricoRevisionTareaDatos>(
      `${environment.urls.api}/contracts/${contratoId}/tasks/${tareaId}/revisions/${revisionId}/update`,
      datos
    );
  }

  createComentario(contratoId, tareaId, revisionId, datos: HistoricoRevisionTareaDatos) {
    return this.http.post<HistoricoRevisionTareaDatos>(
      `${environment.urls.api}/contracts/${contratoId}/tasks/${tareaId}/revisions/${revisionId}/createComment`,
      datos
    );
  }

  uploadDocumento(contratoId, tareaId, revisionId, datos: UploadDocumento) {
    const input = new FormData();
    input.append('file', datos.file);
    input.append('nombre', datos.nombre);
    input.append('observaciones', datos.observaciones);
    if (datos.entregableId) input.append('entregableId', datos.entregableId);
    return this.http.post<HistoricoRevisionTareaDatos>(
      `${environment.urls.api}/contracts/${contratoId}/tasks/${tareaId}/revisions/${revisionId}/createDoc`,
      input
    );
  }


  getAllDocuments(idContrato: number, idTarea: number, idRevision: number, searchRequest: SearchRequest = new SearchRequest()) {
    return this.http.post<DocumentoRevision[]>(`${environment.urls.api}/contracts/${idContrato}/tasks/${idTarea}/revisions/${idRevision}/docs/list`, searchRequest);
  }

  create(contratoId: number, tareaId: number, data: Revision) {
    return this.http.post<Revision>(`${environment.urls.api}/contracts/${contratoId}/tasks/${tareaId}/revisions/create`, data);
  }


  accionMasiva(searchRequest: SearchRequest, accion, data: any) {
    return this.http.post(`${environment.urls.api}/revisions/accion-masiva`, {
      searchRequest,
      accion,
      data
    });
  }

  accionLista(ids: number[], accion, data: any) {
    return this.http.post(`${environment.urls.api}/revisions/accion-lista`, { ids, accion, data });
  }
}
