import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PrioridadTarea } from '@shared/models/home.model';

@Component({
  selector: 'app-tag-prioridad-tarea',
  template: `

    <div class="inline-flex rounded-sm {{prioridadActiva?.class}} {{wrapperClass}}" [title]="prioridadActiva?.label">
      <span
        class="text-xs {{tagClass ? tagClass : 'py-px px-2'}}">{{priority ? priority.codigo : prioridadActiva.label}}</span>
    </div>
  `
})
export class TagPrioridadTareaComponent implements OnInit, OnChanges {
  // TODO: Llamar a base de datos para obtener las prioridades
  @Input() onlyActive = false;
  @Input() priority: PrioridadTarea;
  @Input() tagClass: string;
  @Input() wrapperClass: string;
  @Input() priorityId: number;
  prioridadActiva;


  prioridades = [
    { id: 1, label: 'Baja', class: 'font-semibold uppercase bg-green-200 text-green-800' },
    { id: 2, label: 'Media', class: 'font-semibold uppercase bg-yellow-200 text-yellow-700' },
    { id: 3, label: 'Alta', class: 'font-semibold uppercase bg-orange-200 text-orange-700' },
    { id: 4, label: 'Urgente', class: 'font-semibold uppercase bg-red-200 text-red-700' }
  ];

  constructor() {
  }

  ngOnInit() {
    this.prioridadActiva = this.prioridades.find(e => e.id === (this.priority ? this.priority.id : this.priorityId));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.priority || changes.priorityId)
      this.prioridadActiva = this.prioridades.find(e => e.id === (this.priority ? this.priority.id : this.priorityId));
  }
}
