import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PrioridadTarea } from '@shared/models/home.model';
import { TareaService } from '../../../../modules/frontoffice/services/tarea.service';

@Component({
  selector: 'app-prioridad-selector',
  template: `
    <az-overlay #dd class="inline-flex w-full h-full" [template]="tpl">
      <div class="{{selectedClass}} group hover:bg-gray-100 cursor-pointer relative">
        <app-tag-prioridad-tarea *ngIf="_prioridadActivaId" tagClass="py-1 leading-3 px-3"
                                 [priorityId]="_prioridadActivaId">
        </app-tag-prioridad-tarea>
        <svg (click)="change(null);$event.stopPropagation()"
             class="h-4 w-4 text-gray-400 hidden right-6 z-10 group-hover:block absolute cursor-pointer"
             *ngIf="_prioridadActivaId != undefined"
             fill="none" stroke="currentColor" viewBox="0 0 24 24"
             xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M12 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2M3 12l6.414 6.414a2 2 0 001.414.586H19a2 2 0 002-2V7a2 2 0 00-2-2h-8.172a2 2 0 00-1.414.586L3 12z"></path>
        </svg>
        <svg class="group-hover:visible absolute w-5 h-5 invisible right-1 inset-y-auto"
             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd" />
        </svg>
      </div>
      <ng-template #tpl>
        <az-dropdown-content titulo="Prioridad" [dropdown]="dd">
          <ul class=" space-y">
            <li *ngFor="let p of prioridades" class="px-2 py-1 w-full hover:cursor-pointer hover:bg-gray-100"
                (click)="$event.stopPropagation();change(p);dd.close();">
              <app-tag-prioridad-tarea tagClass="h-8 leading-8 cursor-pointer  px-2 w-full"
                                       wrapperClass="w-full text-center uppercase" [priorityId]="p.id">
              </app-tag-prioridad-tarea>
            </li>
          </ul>
          <button azButton styleClass="w-full justify-center mt-2"
                  (click)="change(null);dd.close();$event.stopPropagation()">
            {{'priority-dropdown.quitar-prioridad' | transloco}}
          </button>
        </az-dropdown-content>
      </ng-template>
    </az-overlay>
  `,
  styles: []
})
export class PrioridadSelectorComponent implements OnInit {
  @Input() prioridad: PrioridadTarea;
  @Input() prioridadId: number;
  @Input() selectedClass = 'px-2 py-1 inline-flex items-center  w-full';

  @Output() onChange = new EventEmitter();

  prioridades = [
    { id: 1, label: 'Baja', class: 'bg-green-200 text-black' },
    { id: 2, label: 'Media', class: 'bg-yellow-200 text-black' },
    { id: 3, label: 'Alta', class: 'bg-red-200 text-black' },
    { id: 4, label: 'Urgente', class: 'bg-red-600 text-white' }
  ];
  _prioridadActivaId: number;

  constructor(private tareaService: TareaService) {
  }

  ngOnInit() {
    this._prioridadActivaId = this.prioridad ? this.prioridad.id : this.prioridadId;
  }

  change(p: { id: number; label: string; class: string }) {
    if (p) {
      this.onChange.emit(p.id);
      this._prioridadActivaId = p.id;
    } else {
      this.onChange.emit(null);
      this._prioridadActivaId = null;
    }
  }

}
