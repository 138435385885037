import { InfoNotification, InfoNotificationType } from '@azigrene/components';

export class Errors {

  errorMessages: InfoNotification[];

  constructor(error: any, key?: string) {
    this.errorMessages = [];
    if (error.errores) { // error validacion de campos
      for (const fieldError of error.errores) {
        this.errorMessages.push(
          new InfoNotification(
            fieldError.mensaje,
            fieldError.campo,
            {
              infoType: InfoNotificationType.ERROR
            })
        );
      }
    } else {
      if (error.error.subErrors) { // error validacion entidad
        for (const fieldError of error.error.subErrors) {
          this.errorMessages.push(
            new InfoNotification(
              fieldError.mensaje,
              fieldError.campo,
              {
                infoType: InfoNotificationType.ERROR
              })
          );
        }
      } else if (error.error.message) { // error lanzado manualmente
        this.errorMessages.push(
          new InfoNotification(
            error.error.message,
            'Error',
            {
              infoType: InfoNotificationType.ERROR
            })
        );
      }
    }
  }

}
