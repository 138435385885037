import {Component, OnInit, Type} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AzModalService} from '@azigrene/components';

interface RouteModalData {
  height: string;
  positionTop: number;
  canClose: boolean;
  styleClass: string;
}

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
})
export class RouteModalComponent implements OnInit {

  componentType: Type<any>;

  constructor(private route: ActivatedRoute, private router: Router, private location: Location, private modalService: AzModalService) {
  }

  close() {
    this.location.back();
  }

  ngOnInit(): void {
    this.componentType = this.route.routeConfig?.component || this.route.snapshot.routeConfig.component;
    const routeData = this.route.snapshot.data;
    const ref = this.modalService.open(this.componentType, {
      styleClass: `w-80/100 md:w-50/100 lg:w-40/100 max-h-90/100 ${routeData.styleClass}`,
      data: {
        ...routeData,
      },
    });

    ref.onClose.subscribe((v) => this.close());
  }

}
