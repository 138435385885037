<div class="p-2 md:w-64">
  <div class="flex items-center justify-center w-full border-b relative">
    <span class="text-gray-500 h-8 leading-8">{{ 'dropdown-periodicidad.titulo' | transloco }}</span>
    <svg (click)="dropdown.close()" class="w-4 h-4 absolute right-0 top-0 mt-1 cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
    </svg>
  </div>

  <div class="az-form-group px-0" (click)="$event.stopPropagation()">
    <label for="periodicidad-seleccion">{{ 'dropdown-periodicidad.repeticion' | transloco }}</label>
    <az-select [options]="periodicidadOptions" name="Periodicidad" id="periodicidad-seleccion" [(ngModel)]="activePeriodicidad" (ngModelChange)="onChangePeriodicidad($event)"> </az-select>
  </div>
  <div>
    <div *ngIf="activePeriodicidad !== null" class="flex flex-col">
      <ng-container *ngIf="activePeriodicidad === PeriodicidadEnum.DIARIO">
        <div class="az-form-group px-0">
          <label for="periodicidad-cada-dias">{{ 'dropdown-periodicidad.cada' | transloco }}</label>
          <select class="az-select" name="periodicidad-cada-dias" id="periodicidad-cada-dias" [(ngModel)]="dayDiario" (ngModelChange)="validate()">
            <option value="*">{{ 'dropdown-periodicidad.every-day' | transloco }}</option>
            <option *ngFor="let cada of cadaDias" value="{{ cada }}">{{ cada }} {{ 'dropdown-periodicidad.days' | transloco }}</option>
          </select>
        </div>
      </ng-container>
      <ng-container *ngIf="activePeriodicidad === PeriodicidadEnum.SEMANAL">
        <table class="az-table az-tablify border">
          <tbody>
            <tr>
              <th class="text-center">{{ 'dateutils.weekday.monday.short' | transloco }}</th>
              <th class="text-center">{{ 'dateutils.weekday.tuesday.short' | transloco }}</th>
              <th class="text-center">{{ 'dateutils.weekday.wednesday.short' | transloco }}</th>
              <th class="text-center">{{ 'dateutils.weekday.thursday.short' | transloco }}</th>
              <th class="text-center">{{ 'dateutils.weekday.friday.short' | transloco }}</th>
              <th class="text-center">{{ 'dateutils.weekday.saturday.short' | transloco }}</th>
              <th class="text-center">{{ 'dateutils.weekday.sunday.short' | transloco }}</th>
            </tr>
            <tr>
              <td class="text-center"><input class="input-checkbox" [checked]="hasWeekday('MON')" (change)="onChangeWeekday('MON')" type="checkbox" /></td>
              <td class="text-center"><input class="input-checkbox" [checked]="hasWeekday('TUE')" (change)="onChangeWeekday('TUE')" type="checkbox" /></td>
              <td class="text-center"><input class="input-checkbox" [checked]="hasWeekday('WED')" (change)="onChangeWeekday('WED')" type="checkbox" /></td>
              <td class="text-center"><input class="input-checkbox" [checked]="hasWeekday('THU')" (change)="onChangeWeekday('THU')" type="checkbox" /></td>
              <td class="text-center"><input class="input-checkbox" [checked]="hasWeekday('FRI')" (change)="onChangeWeekday('FRI')" type="checkbox" /></td>
              <td class="text-center"><input class="input-checkbox" [checked]="hasWeekday('SAT')" (change)="onChangeWeekday('SAT')" type="checkbox" /></td>
              <td class="text-center"><input class="input-checkbox" [checked]="hasWeekday('SUN')" (change)="onChangeWeekday('SUN')" type="checkbox" /></td>
            </tr>
          </tbody>
        </table>
      </ng-container>
      <ng-container *ngIf="activePeriodicidad === PeriodicidadEnum.ANUAL">
        <div class="az-form-group px-0">
          <label for="periodicidad-cada-year">{{ 'dropdown-periodicidad.cada' | transloco }}</label>
          <select class="az-select" name="periodicidad-cada-year" id="periodicidad-cada-year" [(ngModel)]="yearAnual" (ngModelChange)="validate()">
            <option value="*">{{ 'dropdown-periodicidad.every-year' | transloco }}</option>
            <option *ngFor="let cada of cadaYear" value="{{ cada }}">{{ cada }} {{ 'dropdown-periodicidad.years' | transloco }}</option>
          </select>
        </div>
        <div class="az-form-group px-0">
          <label for="periodicidad-year-month">{{ 'dropdown-periodicidad.year-month' | transloco }}</label>
          <select class="az-select" name="periodicidad-year-month" id="periodicidad-year-month" [(ngModel)]="cronParts.month" (ngModelChange)="validate()">
            <option *ngFor="let cada of months" value="{{ cada.id }}">{{ cada.name }}</option>
          </select>
        </div>
        <div class="az-form-group px-0">
          <label for="periodicidad-year-dias">{{ 'dropdown-periodicidad.dia-mes' | transloco }}</label>
          <select class="az-select" name="periodicidad-year-dias" id="periodicidad-year-dias" [(ngModel)]="cronParts.day" (ngModelChange)="generarExpresionCron()">
            <option *ngFor="let diaMes of diasMes" [value]="diaMes">{{ diaMes }}</option>
            <option value="L">{{ 'dropdown-periodicidad.ultimo-dia' | transloco }}</option>
          </select>
        </div>
      </ng-container>
      <ng-container *ngIf="isPeriodicidadMensual(activePeriodicidad)">
        <div class="az-form-group px-0">
          <label for="periodicidad-cada-month">{{ 'dropdown-periodicidad.cada' | transloco }}</label>
          <select class="az-select" name="periodicidad-cada-month" id="periodicidad-cada-month" [(ngModel)]="monthMensual" (ngModelChange)="validate()">
            <option value="*">{{ 'dropdown-periodicidad.every-month' | transloco }}</option>
            <option *ngFor="let cada of cadaMonth" value="{{ cada }}">{{ cada }} {{ 'dropdown-periodicidad.months' | transloco }}</option>
          </select>
        </div>
        <div class="az-form-group px-0">
          <label for="periodicidad-dia-mes">{{ 'periodicidadForm.diaMes' | transloco }}</label>
          <select class="az-select" name="periodicidad-dia-mes" id="periodicidad-dia-mes" [(ngModel)]="cronParts.day" (ngModelChange)="validate()">
            <option *ngFor="let diaMes of diasMes" [value]="diaMes">{{ diaMes }}</option>
            <option value="L">{{ 'dropdown-periodicidad.ultimo-dia' | transloco }}</option>
          </select>
        </div>
      </ng-container>
      <ng-container *ngIf="activePeriodicidad === PeriodicidadEnum.PUNTUAL">
        <label>{{ 'periodicidadForm.fecha' | transloco }}</label>
        <nz-date-picker class="w-full" [(ngModel)]="fechaSeleccionada" (ngModelChange)="validate()"></nz-date-picker>
      </ng-container>
    </div>

    <div *ngIf="activePeriodicidad !== undefined">
      <input [(ngModel)]="expresionCron" appendTo="body" type="text" type="hidden" />
    </div>
  </div>
  <div class="az-form-group px-0" (click)="$event.stopPropagation()">
    <label for="fecha-aplicacion">{{ 'dropdown-periodicidad.fecha-aplicacion' | transloco }}</label>
    <nz-date-picker id="fecha-aplicacion" [(ngModel)]="fechaAplicacion" (ngModelChange)="validate()" [nzDefaultPickerValue]="fechaAplicacion"></nz-date-picker>
  </div>
  <div class="w-full flex justify-end mt-4">
    <button azButton level="primary" [disabled]="!this.valid" (click)="updatePeriodicty();">
      {{ 'actions.save' | transloco }}
    </button>
  </div>
</div>
<!-- <az-dropdown-content titulo="Repetición" [dropdown]="dropdown">
  </az-dropdown-content> -->
