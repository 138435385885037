export enum PeriodicidadEnum {
  DIARIO = 'Diario',
  SEMANAL = 'Semanal',
  MENSUAL = 'Mensual',
  BIMESTRAL = 'Bimestral',
  TRIMESTRAL = 'Trimestral',
  CUATRIMESTRAL = 'Cuatrimestral',
  SEMESTRAL = 'Semestral',
  ANUAL = 'Anual',
  PUNTUAL = 'Puntual'
}

export function getPeriodicidadByCodigo(periodicidad: string): PeriodicidadEnum {
  return Object.values(PeriodicidadEnum).find(p => p.toLowerCase().includes(periodicidad?.toLowerCase()));
}

export type Periodicidad = 'Diario' |
  'Semanal' |
  'Mensual' |
  'Bimestral' |
  'Trimestral' |
  'Cuatrimestral' |
  'Semestral' |
  'Anual' |
  'Puntual';

export type PeriodicidadVisual = 'Cada día' |
  'Cada semana' |
  'Cada 2 semanas' |
  'Cada 3 semanas' |
  'Cada mes' |
  'Cada 2 meses' |
  'Cada 3 meses' |
  'Cada 4 meses' |
  'Cada 6 meses' |
  'Cada año' |
  'Cada 2 años' |
  'Cada 3 años' |
  'Puntual';

