import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EstadoRevision } from '@shared/models/home.model';
import { TareaService } from '../../../../modules/frontoffice/services/tarea.service';
import { AzOverlayComponent } from '@azigrene/components';

@Component({
  selector: 'app-estado-selector-dropdown',
  template: `
    <az-dropdown-content titulo="Estado" [dropdown]="dropdown">
      <ul class=" space-y">
        <li *ngFor="let e of estados" class="px-2 py-1 w-full hover:cursor-pointer hover:bg-gray-100"
            (click)="$event.stopPropagation();change(e);dropdown.close();">
          <app-tag-estado-revision tagClass="h-7 leading-7 cursor-pointer px-2 w-full"
                                   wrapperClass="w-full text-center uppercase"
                                   [statusId]="e.id">
          </app-tag-estado-revision>
        </li>
      </ul>
    </az-dropdown-content>
  `,
  styles: []
})
export class EstadoSelectorDropdownComponent implements OnInit {
  @Input() dropdown: AzOverlayComponent;
  @Input() contratoId: number;
  @Input() tareaId: number;
  @Input() estado: EstadoRevision;
  @Input() estadoId: number;

  @Output() onChange = new EventEmitter<number>();
  @Output() onUpdateSuccess = new EventEmitter();

  estados = [
    { id: 1, label: 'PENDIENTE' },
    { id: 2, label: 'EN PROCESO' },
    { id: 3, label: 'FINALIZADA' },
    { id: 4, label: 'VALIDADA' },
    { id: 5, label: 'RECHAZADA' },
    { id: 6, label: 'CANCELADA' }
  ];
  _estadoActivoId: number;

  constructor(private tareaService: TareaService) {
  }

  ngOnInit() {
    this._estadoActivoId = this.estado ? this.estado.id : this.estadoId;
  }

  change(o: { id: number; label: string; class: string }) {
    if (o) {
      this.onChange.emit(o.id);
      this._estadoActivoId = o.id;
    } else {
      this.onChange.emit(null);
      this._estadoActivoId = null;
    }
  }
}
