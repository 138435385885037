import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-section',
  template: `
    <div *ngIf="title" azFormSectionHeader styleClass="pl-0" [title]="title" [subtitle]="description"></div>
    <div class="grid grid-cols-6 gap-4">
      <ng-content></ng-content>
      <ng-content select="[azFormContent]"></ng-content>
    </div>
    <div azFormSectionFooter>
      <ng-content select="[azFormFooter]"></ng-content>
    </div>
    <hr *ngIf="divider" azFormSectionDivider>
  `,
  styles: []
})
export class FormSectionComponent implements OnInit {

  @HostBinding('class') class = 'block mb-8';
  @Input() title: any;
  @Input() styleClass: string;
  @Input() divider = false;
  @Input() gridCols = 6;
  @Input() description: any;

  ngOnInit(): void {
    this.class = `${this.class} ${this.styleClass}`;
  }

}

@Component({
  selector: '[azFormSpace]',
  template: `
    <ng-content></ng-content>
  `,
  styles: []
})
export class FormSpaceComponent implements OnInit {

  @HostBinding('class') class;
  @Input() breakpoint: 'md' | 'sm' | 'lg' | 'xl' = 'md';
  @Input() gridCols = 3;

  ngOnInit(): void {
    this.class = `col-span-${this.gridCols} invisible ${this.breakpoint}:block`;
  }

}

@Component({
  selector: 'app-form-subsection',
  template: `
    <div class="flex flex-wrap w-full mt-8">
      <p *ngIf="title" class="form-subsection-title mb-2 text-gray-600 font-medium px-2 w-full">{{ title  }}</p>
      <ng-content></ng-content>
    </div>`,
  styles: []
})
export class SubSectionComponent {

  @Input() title: any;

}
