import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UploadDocumento } from '@shared/models/generic.model';
import { environment } from 'src/environments/environment';

@Injectable()
export class DocumentoService {

  constructor(private http: HttpClient) {
  }

  uploadDocumentoContrato(contratoId: number, datos: UploadDocumento) {
    const input = new FormData();

    input.append('file', datos.file);
    input.append('nombre', datos.nombre);
    input.append('observaciones', datos.observaciones);
    const req = new HttpRequest('POST', `${environment.urls.api}/contracts/${contratoId}/docs/create`, input);


    return this.http.request(req);
  }

  downloadDocument(id: number) {
    return this.http.get(`${environment.urls.api}/docs/${id}/download`, { responseType: 'blob' });
  }

  deleteDocument(id: number) {
    return this.http.post(`${environment.urls.api}/docs/${id}/delete`, {});
  }

}
