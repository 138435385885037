import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'inputError'
})
export class InputErrorPipe implements PipeTransform {

  transform(value: any, ...args: any[]): string {
    let entered = false;
    let rvalue = '';

    if (value) {
      if (typeof value === 'string') {
        if (entered) {
          rvalue += '\n ';
        }

        rvalue = '* ' + value;
      } else {
        Object.values(value).forEach((message) => {
          if (typeof message !== 'undefined' && typeof message !== 'boolean') {
            if (entered) {
              rvalue += '\n ';
            }

            entered = true;
            rvalue = '* ' + message;
          }
        });
      }
    } else {
      rvalue = '';
    }

    return rvalue;
  }

}
