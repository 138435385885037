import {EnumModel} from "@shared/models/enum.model";

export const EstadoRevisionEnum: {[id: string]: EnumModel} = {
  PENDIENTE: {
    id: 1,
    codigo: 'Pendiente'
  },
  EN_PROCESO: {
    id: 2,
    codigo: 'En proceso'
  },
  FINALIZADA: {
    id: 3,
    codigo: 'Finalizada'
  },
  VALIDADA: {
    id: 4,
    codigo: 'Validada'
  },
  RECHAZADA: {
    id: 5,
    codigo: 'Rechazada'
  },
  CANCELADA: {
    id: 6,
    codigo: 'Cancelada'
  }
}

export function getEstadoRevisionById(estadoRevisionId: number): EnumModel {
  return Object.values(EstadoRevisionEnum).find(estadoRevision => estadoRevision.id == estadoRevisionId);
}

export function getEstadoRevisionIdByCodigo(estadoRevisionCodigo: string): number {
  return Object.values(EstadoRevisionEnum).find(estadoRevision => estadoRevision.codigo == estadoRevisionCodigo).id;
}
