import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class PasswordService {
  constructor(private http: HttpClient) {
  }

  resetPassword(email) {
    const req = new HttpRequest('POST', `${environment.urls.api}/resetPassword`, { email });
    return this.http.request(req);
  }

  changePassword(id, token, password) {
    const req = new HttpRequest('POST', `${environment.urls.api}/changePassword`, { usuarioId: id, token: token, password: password });
    return this.http.request(req);
  }
}
