import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, ResolveEnd, Router, RouterEvent} from '@angular/router';
import {filter} from 'rxjs/operators';
import {Location} from '@angular/common';
import {DataUtils} from '@azigrene/utils';

export interface IBreadCrumb {
  label: string;
  params: { [id: string]: any };
  resolved: { [id: string]: any };
  isResolved: boolean;
  url: string;
  clickable: boolean;
  last: boolean;
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
})
export class BreadcrumbComponent implements OnInit {
  public breadcrumbs: IBreadCrumb[];

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private _location: Location) {
    this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
    this.router.events.pipe(filter((event: RouterEvent) => event instanceof NavigationEnd)).subscribe(() => {
      this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
    });

    this.router.events.pipe(filter((event: RouterEvent) => event instanceof ResolveEnd)).subscribe(() => {
      this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
    });
  }

  ngOnInit() {
  }

  /**
   * Recursively build breadcrumb according to activated route.
   */
  buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: IBreadCrumb[] = []): IBreadCrumb[] {
    // If no routeConfig is avalailable we are on the root path
    let params = {};
    let data = {};
    let label = route.routeConfig && route.routeConfig.data ? route.routeConfig.data.breadcrumb : '';
    const isClickable = route.routeConfig && route.routeConfig.data && (route.routeConfig.data.isClickable || route.routeConfig.data.isClickable === undefined);
    let path = route.routeConfig && route.routeConfig.data ? route.routeConfig.path : '';

    // If the route is dynamic route such as ':id', remove it
    const lastRoutePart = path.split('/').pop();
    const isDynamicRoute = lastRoutePart.startsWith(':');
    let isResolved = false;
    if (isDynamicRoute && !!route.snapshot) {
      const paramName = lastRoutePart.split(':')[1];
      const regexp = /(:\S+)/g;
      let resolverItem = regexp.exec(label);
      while (resolverItem != null) {
        isResolved = true;
        const resolverPath = resolverItem[0].replace(':', '');
        data[resolverItem[0]] = DataUtils.getByPath(route.snapshot.data, resolverPath);
        resolverItem = regexp.exec(label);
      }
      path = path.replace(lastRoutePart, route.snapshot.params[paramName]);
      label = `${label}`;
      for (let key of Object.keys(data)) {
        label = label.replace(key, data[key]);
      }
      params = {[paramName]: route.snapshot.params[paramName]};
    }

    // In the routeConfig the complete path is not available,
    // so we rebuild it each time
    let nextUrl;
    if (Object.keys(params).length) {
      nextUrl = url;
    } else {
      nextUrl = path ? `${url}/${path}` : url;
    }
    const breadcrumb: IBreadCrumb = {
      label,
      params,
      resolved: data,
      isResolved,
      url: nextUrl,
      clickable: isDynamicRoute ? false : isClickable,
      last: route.firstChild == undefined
    };
    // Only adding route with non-empty label
    const newBreadcrumbs = breadcrumb.label ? [...breadcrumbs, breadcrumb] : [...breadcrumbs];
    if (route.firstChild) {
      // If we are not on our current path yet,
      // there will be more children to look after, to build our breadcumb
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }
    return newBreadcrumbs;
  }

  back() {
    this._location.back();
  }
}
