import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { PaginatedResponse, SearchRequest } from '@azigrene/searchrequest';
import { Usuario } from '@shared/models/auth.model';
import {Observable} from "rxjs";

@Injectable()
export class BackofficeUsuarioService {
  constructor(private http: HttpClient) {
  }

  all() {
    return this.http.get<Usuario[]>(`${environment.urls.api}/backoffice/users`);
  }

  allPaginated(searchRequest: SearchRequest) {
    return this.http.post<PaginatedResponse<Usuario>>(
      `${environment.urls.api}/backoffice/users`,
      searchRequest
    );
  }

  one(id: number) {
    return this.http.get<Usuario>(`${environment.urls.api}/backoffice/users/${id}`);
  }

  create(data: Usuario) {
    return this.http.post<Usuario>(
      `${environment.urls.api}/backoffice/users/create`,
      data
    );
  }

  update(id: number, data: Usuario) {
    return this.http.post<Usuario>(
      `${environment.urls.api}/backoffice/users/${id}/update`,
      data
    );
  }

  delete(id: number) {
    return this.http.delete<Usuario>(
      `${environment.urls.api}/backoffice/users/${id}/delete`
    );
  }

  accionLista(ids: number[], accion, data: any): Observable<number> {
    return this.http.post<number>(`${environment.urls.api}/backoffice/users/accion-lista`, {ids, accion, data});
  }

  accionMasiva(searchRequest: SearchRequest, accion, data: any): Observable<number> {
    return this.http.post<number>(`${environment.urls.api}/backoffice/users/accion-masiva`, {searchRequest, accion, data})
  }
}
