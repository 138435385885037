import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthState } from 'src/app/store/auth/auth.store';
import { map } from 'rxjs/operators';

@Injectable()
export class MaestroGuardService implements CanActivate {
  constructor(private router: Router, private store: Store) {}

  canActivate() {
    return this.store.select(AuthState.getUser).pipe(
      map(user => {
        if (!user.maestroDatos) {
          this.router.navigateByUrl('/home');
          return false;
        } else {
          return true;
        }
      })
    );
  }
}
