import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './pages/login/login.component';
import {AuthGuardService} from '@store/guards/auth-guard.service';
import {MaestroGuardService} from '@store/guards/maestro-guard.service';
import {ChangePasswordComponent} from './pages/change-password/change-password.component';

const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {path: 'changePassword/:id/:token', component: ChangePasswordComponent},
  {path: 'home', loadChildren: () => import('./modules/frontoffice/frontoffice.module').then((m) => m.FrontofficeModule), canActivate: [AuthGuardService]},
  {path: 'backoffice', loadChildren: () => import('./modules/backoffice/backoffice.module').then((m) => m.BackofficeModule), canActivate: [MaestroGuardService]},
  {path: '', pathMatch: 'full', redirectTo: 'home'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {paramsInheritanceStrategy: 'always'})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
