import {Component, EventEmitter, Input, Output} from '@angular/core';
import {getPeriodicidadByCodigo} from "@shared/enum/enums";
import {Frecuencia} from "@shared/utils";

@Component({
  selector: 'app-periodicity-selector',
  template: `
    <az-overlay #dd class="flex my-1 w-full " [template]="tpl" dropdownClass="max-w-sm w-full">
      <ng-container *ngIf="periodicidadSeleccionada">
        <div class="cursor-pointer h-7 w-full px-2 rounded-sm bg-gray-100 flex items-center justify-center space-x-2">
          <svg class="w-3 h-3 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24"
               xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"></path>
          </svg>
          <span>{{ periodicidadSeleccionada }}</span>
        </div>
      </ng-container>
      <div *ngIf="!periodicidadSeleccionada"
           class="cursor-pointer h-7 px-2 rounded-sm bg-gray-100 flex items-center justify-center">
        <svg class="w-4 h-4 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24"
             xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
        </svg>
      </div>
    </az-overlay>

    <ng-template #tpl>
      <app-periodicity-dropdown [dropdown]="dd" [activePeriodicidad]="getPeriodicidadByCodigo(periodicidadSeleccionada)"
                                [expresionCron]="expresionCron" [tareaId]="tareaId" (onChange)="onChange.emit($event)"
                                (onUpdateSuccess)="onUpdateSuccess.emit($event);periodicityChange($event);"
                                [contratoId]="contratoId" [frecuencia]="frecuencia"></app-periodicity-dropdown>
    </ng-template>
  `,
  styles: []
})
export class PeriodicitySelectorComponent {
  @Input() contratoId: number;
  @Input() tareaId: number;
  @Input() expresionCron: string;
  @Input() periodicidadSeleccionada: string;
  @Input() frecuencia: Frecuencia;
  @Output() onChange = new EventEmitter();
  @Output() onUpdateSuccess = new EventEmitter();

  valid: boolean;
  getPeriodicidadByCodigo = (periodicidadCodigo: string) => getPeriodicidadByCodigo(periodicidadCodigo);

  periodicityChange(value: { cron: string, periodicidad: string }) {
    this.periodicidadSeleccionada = value.periodicidad;
  }

}
