import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { AssigneeModel } from '@azigrene/components';
import { MiembroService } from '../../../../modules/frontoffice/services/miembro.service';
import { MiembrosArray } from '@shared/models/home.model';
import { Miembro, MiembroTarea } from '@shared/models/auth.model';
import { TareaActions } from '../../../../modules/frontoffice/actions/TareaActions';
import { TareaService } from '../../../../modules/frontoffice/services/tarea.service';
import {map, take} from 'rxjs/operators';

@Component({
  selector: 'app-assignees-selector',
  template: `
    <az-assignees-circles class="flex-1"
                          [assigneeListObservable]="assigneeObs"
                          (ngModelChange)="updateAssignees($event)"
                          [editable]="true" [ngModel]="_assignees">
    </az-assignees-circles>
  `,
  styles: []
})
export class AssigneesSelectorComponent implements OnInit, OnChanges {

  @Input() contratoId: number;
  @Input() tareaId: number;
  @Input() assignees: MiembrosArray;
  @Output() updateSuccess = new EventEmitter();

  assigneeObs: () => Observable<AssigneeModel[]>;
  _assignees: AssigneeModel[];

  constructor(private miembroService: MiembroService, private tareaService: TareaService) {
    this.assigneeObs = () => this.miembroService.all(this.contratoId).pipe(map((e: Miembro[]) => {
      return e.map(v => ({
        id: v.id,
        username: v.usuario?.email
      }));
    }));
  }

  ngOnInit(): void {
  }

  updateAssignees(event: AssigneeModel[]): void {
    const members: MiembroTarea[] = event.map(e => e.id);

    this.tareaService.accionLista([this.tareaId], TareaActions.SET_ASSIGNEES, { assignees: members }, this.contratoId).pipe(take(1)).subscribe(n => {
      if (n) {
        this.updateSuccess.emit();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.assignees) {
      this._assignees = changes.assignees.currentValue ? changes.assignees.currentValue.jsonArray.map(v => ({
        id: v.miembroId,
        username: v.email
      })) : [];
    }
  }

}
