import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AuthGuardService} from './guards/auth-guard.service';
import {AuthService} from '../service/auth.service';
import {MaestroGuardService} from './guards/maestro-guard.service';
import {SharedModule} from '@shared/shared.module';
import {NgxsModule} from '@ngxs/store';
import {AuthState} from '@store/auth/auth.store';
import {environment} from '../../environments/environment';
import {NgxsLoggerPluginModule} from '@ngxs/logger-plugin';
import {NgxsStoragePluginModule} from '@ngxs/storage-plugin';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedModule,
    NgxsModule.forRoot([AuthState], {developmentMode: !environment.production}),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    // NgxsRouterPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot({
      disabled: environment.production,
    }),
    NgxsStoragePluginModule.forRoot({
      key: ['auth', 'home'],
    }),
  ],
  exports: [],
  providers: [AuthGuardService, MaestroGuardService, AuthService],
})
export class StoreModule {}
