<div class="flex items-center">
  <input id="components.form-file.input" #inputElement (change)="onChangeInputFile($event)" [type]="type" class="hidden" />
  <div
    class="border-2 border-dashed color-border-primary color-text-primary flex flex-col justify-center p-4 rounded w-full h-64"
    [class.bg-gray-50]="dragging"
    (dragover)="onDragOver($event)"
    (dragleave)="onDragLeave($event)"
    (dragend)="onDragEnd($event)"
    (drop)="onDrop($event)"
  >
    <ng-container *ngIf="!dragging">
      <div class="text-center">
        <div class="flex justify-center mb-2" *ngIf="requiredType?.includes('image/')">
          <az-icon *ngIf="!value" icon="photograph" type="outline" size="custom" iconClass="h-20 w-20 color-icon-secondary"></az-icon>
          <img class="h-12 mx-auto" *ngIf="showPreview && value && !value?.bytes" src="{{ value }}" />
        </div>
        <div class="bg-accent flex-col inline-flex items-center p-2 rounded shadow text-white" *ngIf="value && returnAsFile">
          <az-icon wrapperClass="mr-2" icon="document" size="lg"></az-icon>
          {{ value.name }}
        </div>
        <p *ngIf="!value">{{ 'components.form-file.arrastre-aqui' | transloco }}</p>
      </div>
      <div class="flex items-center my-4">
        <hr class="flex-1" />
        <span class="mx-4">{{ 'components.form-file.o' | transloco }}</span>
        <hr class="flex-1" />
      </div>
      <div class="flex justify-center">
        <button azButton level="secondary" (click)="inputElement.click()">
          {{ 'components.form-file.seleccione' | transloco }}
        </button>
      </div>
      <div class="flex justify-center">
        <p class="text-danger-400" *ngIf="errorFile">{{ 'components.form-file.file-error-format-message' | transloco }}</p>
      </div>
    </ng-container>
    <ng-container *ngIf="dragging">
      <az-icon icon="archive" size="custom" wrapperClass="mx-auto" iconClass="h-32 w-32 color-icon-secondary"></az-icon>
    </ng-container>
  </div>
</div>
